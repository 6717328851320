import { Currency, CurrencyAmount, Price, TradeType } from '@uniswap/sdk-core'
import { useBestTradeLH } from 'components/swap/LiquidityHub'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { IHasQuoteProperties, QuoteMethod, TradeFillType } from 'state/routing/types'
import { computeFiatValuePriceImpact } from 'utils/computeFiatValuePriceImpact'

import { calculateFiatValue, QuoterProps } from './QuoteManager'
import { Quoters } from './QuoteManager' // Enum for quoters

export const LiquidityHubQuoter: React.FC<QuoterProps> = ({
  swapParams,
  onQuoteUpdate,
  inputFiatValuePerToken,
  outputFiatValuePerToken,
}: QuoterProps) => {
  const { allowedSlippage, inputCurrency, outputCurrency, amount, tradeType, inputTax, outputTax } = swapParams

  const rateInputAmount = useMemo(() => {
    if (!inputCurrency) return undefined
    return CurrencyAmount.fromRawAmount(inputCurrency, amount.toString())
  }, [inputCurrency, amount])

  const lhHubParams = useMemo(() => {
    return {
      allowedSlippage,
      isEnabled: tradeType === TradeType.EXACT_INPUT,
      inputCurrency,
      outputCurrency,
      inputAmount: amount.toString(),
    }
  }, [allowedSlippage, amount, inputCurrency, outputCurrency, tradeType])

  const lh = useBestTradeLH(lhHubParams)

  // const lh = useMemo(() => {
  //   return lhRaw
  // }, [lhRaw])

  const liquidityHubSwap = useMemo(() => lh?.swap, [lh.swap])
  const liquidityHubInitAnalytics = useMemo(() => lh?.analyticsInit, [lh.analyticsInit])
  const liquidityHubQuote = lh.useQuote()

  const rateOutputAmount = useMemo(() => {
    if (!outputCurrency || !liquidityHubQuote.data) return undefined
    return CurrencyAmount.fromRawAmount(outputCurrency, liquidityHubQuote.data.outAmount ?? '0')
  }, [outputCurrency, liquidityHubQuote.data])

  const fiatValueInputMemo = useMemo(() => {
    if (!rateInputAmount) {
      return { data: undefined, isLoading: false }
    }
    return { data: calculateFiatValue(inputFiatValuePerToken, rateInputAmount), isLoading: false }
  }, [rateInputAmount, inputFiatValuePerToken])

  const fiatValueOutputMemo = useMemo(() => {
    if (!rateOutputAmount) {
      return { data: undefined, isLoading: false }
    }
    return { data: calculateFiatValue(outputFiatValuePerToken, rateOutputAmount), isLoading: false }
  }, [rateOutputAmount, outputFiatValuePerToken])

  const preTaxStablecoinPriceImpact = useMemo(() => {
    if (!fiatValueInputMemo?.data || !fiatValueOutputMemo?.data || !rateInputAmount || !rateOutputAmount) {
      return undefined
    }

    return computeFiatValuePriceImpact(fiatValueInputMemo?.data, fiatValueOutputMemo?.data)
  }, [fiatValueInputMemo?.data, fiatValueOutputMemo?.data, rateInputAmount, rateOutputAmount])

  const resolveQuoteFromLiquidityHub = useCallback(
    (rateInputAmount: CurrencyAmount<Currency>, rateOutputAmount: CurrencyAmount<Currency>): IHasQuoteProperties => {
      return {
        inputAmount: rateInputAmount,
        outputAmount: rateOutputAmount,
        executionPrice: new Price(
          rateInputAmount.currency,
          rateOutputAmount.currency,
          rateInputAmount.quotient,
          rateOutputAmount.quotient
        ),
        quote: undefined,
        quoteMethod: QuoteMethod.LIQUIDITY_HUB,
        fillType: TradeFillType.BestSwap,
        tradeType: TradeType.EXACT_INPUT,
        postTaxOutputAmount: rateOutputAmount,
        approveInfo: { needsApprove: false },
        gasUseEstimateUSD: undefined,
        totalGasUseEstimateUSD: undefined,
        priceImpact: preTaxStablecoinPriceImpact,
        fiatValueInput: fiatValueInputMemo,
        fiatValueOutput: fiatValueOutputMemo,
        quoter: Quoters.LIQUIDITY_HUB,
        dependencies: { liquidityHubSwap, liquidityHubInitAnalytics },
      }
    },
    [preTaxStablecoinPriceImpact, fiatValueInputMemo, fiatValueOutputMemo, liquidityHubSwap, liquidityHubInitAnalytics]
  )

  const prevQuoteRef = useRef<IHasQuoteProperties | null>(null)
  useEffect(() => {
    if (!rateInputAmount || !rateOutputAmount) {
      return
    }

    const isLHSwapSupport = inputTax.equalTo(0) && outputTax.equalTo(0) && tradeType === TradeType.EXACT_INPUT
    if (isLHSwapSupport) {
      const resolvedQuote = resolveQuoteFromLiquidityHub(rateInputAmount, rateOutputAmount)

      if (!prevQuoteRef.current || JSON.stringify(prevQuoteRef.current) !== JSON.stringify(resolvedQuote)) {
        if (resolvedQuote.outputAmount.equalTo(0)) {
          onQuoteUpdate(Quoters.LIQUIDITY_HUB, null)
        } else {
          onQuoteUpdate(Quoters.LIQUIDITY_HUB, { ...resolvedQuote, isValid: true })
          prevQuoteRef.current = resolvedQuote
        }
      }
    }
  }, [inputTax, outputTax, resolveQuoteFromLiquidityHub, tradeType, rateInputAmount, rateOutputAmount, onQuoteUpdate])

  return null // No UI needed, just logic
}
