import { useTokenPriceCache } from 'hooks/useRPCTokenPriceFetcher'
import React, { useEffect } from 'react'
import { useCacheActionHandlers } from 'state/cache/hooks'

// Component that fetches token prices and notifies the parent
// eslint-disable-next-line import/no-unused-modules
export const TokenPriceFetcherComponent: React.FC<{ onFetchComplete: () => void }> = ({ onFetchComplete }) => {
  const { onRPCTokenPricesChange } = useCacheActionHandlers()

  // Fetch token prices
  const tokenPrices = useTokenPriceCache()

  // Dispatch token prices once fetched
  useEffect(() => {
    if (tokenPrices && Object.keys(tokenPrices).length !== 0) {
      // Dispatch token prices
      onRPCTokenPricesChange(tokenPrices)
      // Notify parent that token prices have been fetched
      onFetchComplete()
    }
  }, [tokenPrices, onRPCTokenPricesChange, onFetchComplete])

  return <></> // Render nothing
}
