import { Trans } from '@lingui/macro'
import { Percent } from '@uniswap/sdk-core'
import { OutlineCard } from 'components/Card'
import styled, { useTheme } from 'styled-components'
import { opacify } from 'theme/utils'
import formatPriceImpact from 'utils/formatPriceImpact'

import { ThemedText } from '../../theme'
import { AutoColumn } from '../Column'
import { RowBetween, RowFixed } from '../Row'
import { MouseoverTooltip } from '../Tooltip'

const StyledCard = styled(OutlineCard)`
  padding: 12px;
  border: 1px solid ${({ theme }) => opacify(24, theme.critical)};
  border-radius: 0px;
  border-right: 0px;
  border-left: 0px;
  margin-top: 20px;
`

interface PriceImpactWarningProps {
  priceImpact?: Percent
}

export default function PriceImpactWarning({ priceImpact }: PriceImpactWarningProps) {
  const theme = useTheme()

  const title = priceImpact ? 'Price impact warning' : 'Price impact could not be calculated'

  const message = priceImpact
    ? `A swap of this size may have a high price impact, given the current liquidity in the pool. There may be a large difference between the amount of your input token and what you will receive in the output token`
    : `The price impact could not be calculated, There may be a large difference between the amount of your input token and what you will receive in the output token`

  return (
    <StyledCard>
      <AutoColumn gap="sm">
        <MouseoverTooltip text={<Trans>{message}</Trans>}>
          <RowBetween>
            <RowFixed>
              <ThemedText.DeprecatedSubHeader color={theme.critical}>
                <Trans>{title}</Trans>
              </ThemedText.DeprecatedSubHeader>
            </RowFixed>
            {priceImpact && (
              <ThemedText.DeprecatedLabel textAlign="right" fontSize={14} color="critical">
                {formatPriceImpact(priceImpact)}
              </ThemedText.DeprecatedLabel>
            )}
          </RowBetween>
        </MouseoverTooltip>
      </AutoColumn>
    </StyledCard>
  )
}
