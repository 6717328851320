import { Trans } from '@lingui/macro'
import { AutoColumn } from 'components/Column'
import Row from 'components/Row'
import { ReactNode } from 'react'
import { IHasTradeProperties } from 'state/routing/types'
import styled from 'styled-components'
import { Divider, ThemedText } from 'theme'
import { formatNumber, NumberType } from 'utils/formatNumbers'

const Container = styled(AutoColumn)`
  padding: 4px;
`

// eslint-disable-next-line import/no-unused-modules
export const GasCostItem = ({
  title,
  amount,
  itemValue,
}: {
  title: ReactNode
  itemValue?: React.ReactNode
  amount?: number
}) => {
  return (
    <Row justify="space-between">
      <ThemedText.SubHeaderSmall>{title}</ThemedText.SubHeaderSmall>
      <ThemedText.SubHeaderSmall color="neutral1">
        {itemValue ??
          formatNumber({
            input: amount,
            type: NumberType.FiatGasPrice,
          })}
      </ThemedText.SubHeaderSmall>
    </Row>
  )
}

export function GasBreakdownTooltip({
  trade,
  hideFees = false,
}: {
  trade: IHasTradeProperties
  hideFees?: boolean
  hideUniswapXDescription?: boolean
}) {
  const swapEstimate = trade.gasUseEstimateUSD
  const approvalEstimate = trade.approveInfo.needsApprove ? trade.approveInfo.approveGasEstimateUSD : undefined

  return (
    <Container gap="md">
      {approvalEstimate && !hideFees && (
        <>
          <AutoColumn gap="sm">
            <GasCostItem
              title={<Trans>Allow {trade.inputAmount.currency.symbol} (one time)</Trans>}
              amount={approvalEstimate}
            />
            {swapEstimate && <GasCostItem title={<Trans>Swap</Trans>} amount={swapEstimate} />}
          </AutoColumn>
          <Divider />
        </>
      )}
    </Container>
  )
}
