import { CurrencyAmount, Percent, Token } from '@uniswap/sdk-core'
import { useMemo } from 'react'
import { IHasQuotePropertiesBase, InterfaceTrade } from 'state/routing/types'
import { rateMaximumAmountIn } from 'utils/calculateSlippageAmount'

export function useMaxAmountIn(trade: InterfaceTrade | undefined, allowedSlippage: Percent) {
  return useMemo(() => {
    const maximumAmountIn = trade?.maximumAmountIn(allowedSlippage)
    return maximumAmountIn?.currency.isToken ? (maximumAmountIn as CurrencyAmount<Token>) : undefined
  }, [allowedSlippage, trade])
}

// eslint-disable-next-line import/no-unused-modules
export function useRateMaxAmountIn(quote: IHasQuotePropertiesBase | undefined, allowedSlippage: Percent) {
  return useMemo(() => {
    if (!quote) {
      return undefined
    }
    const maxAmountIn = rateMaximumAmountIn(quote, allowedSlippage, quote.inputAmount)
    return maxAmountIn.currency.isToken ? (maxAmountIn as CurrencyAmount<Token>) : undefined
  }, [allowedSlippage, quote])
}
