import { Plural, Trans } from '@lingui/macro'
import {
  ConfirmationPoweredBy,
  LiquidityHubProvider,
  PoweredByOrbs as PoweredBy,
  supportedChains,
  SwapConfirmation,
  useFormatNumber,
  useGasCostUsd,
  usePriceChanged,
  useQuote,
  useRate,
  useSwapButton,
  useSwapConfirmation,
} from '@orbs-network/liquidity-hub-ui-sdk'
import { Currency, Percent } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import BN from 'bignumber.js'
import { useToggleAccountDrawer } from 'components/AccountDrawer'
import AnimatedDropdown from 'components/AnimatedDropdown'
import { ButtonPrimary, SmallButtonPrimary } from 'components/Button'
import Column from 'components/Column'
import { Gas as GasIcon } from 'components/Icons/Gas'
import Modal from 'components/Modal'
import QuestionHelper from 'components/QuestionHelper'
import Row, { RowBetween, RowFixed } from 'components/Row'
import { MouseoverTooltip } from 'components/Tooltip'
import { ConfirmationModalContent } from 'components/TransactionConfirmationModal'
import { WRAPPED_NATIVE_CURRENCY } from 'constants/tokens'
import useNativeCurrency from 'lib/hooks/useNativeCurrency'
import { ReactNode, useCallback } from 'react'
import { AlertTriangle, ChevronDown } from 'react-feather'
import { Field } from 'state/swap/actions'
import styled, { css, CSSProperties, DefaultTheme, keyframes, useTheme } from 'styled-components'
import { Separator, ThemedText } from 'theme'

import { SwapShowAcceptChanges } from '../styled'
import { Label } from '../SwapModalHeaderAmount'
import { LH_ROUTER_LABEL } from './consts'
import { useUpdateBalancesAfterLhSwap } from './hooks'
import { SwapRouterOption, SwapRouterOptions } from './types'

const StyledPolling = styled.div`
  display: flex;
  height: 16px;
  width: 16px;
  margin-right: 2px;
  margin-left: 2px;
  align-items: center;
  color: ${({ theme }) => theme.neutral1};
  transition: 250ms ease color;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    display: none;
  `}
`

const StyledAlertTriangle = styled(AlertTriangle)`
  margin-right: 8px;
  min-width: 24px;
`

const StyledPollingDot = styled.div`
  width: 8px;
  height: 8px;
  min-height: 8px;
  min-width: 8px;
  border-radius: 50%;
  position: relative;
  background-color: ${({ theme }) => theme.surface3};
  transition: 250ms ease background-color;
`

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  animation: ${rotate360} 1s cubic-bezier(0.83, 0, 0.17, 1) infinite;
  transform: translateZ(0);
  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-left: 2px solid ${({ theme }) => theme.neutral1};
  background: transparent;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: relative;
  transition: 250ms ease border-color;
  left: -3px;
  top: -3px;
`

const RouterSelectOption = styled(Row)<{ isActive: boolean }>`
  justify-content: center;
  align-items: center;
  text-align: center;
  width: auto;
  cursor: pointer;
  padding: 6px 12px;
  text-align: center;
  gap: 4px;
  border-radius: 12px;
  white-space: nowrap;
  background: ${({ isActive, theme }) => (isActive ? theme.surface3 : 'transparent')};
  pointer-events: ${({ isActive }) => isActive && 'none'};
`

const shineAnimation = keyframes`
  to {
    background-position: 200% center;
  }
`

const RouterSelectOptionOutsider = styled(Row)<{ isActive: boolean }>`
  width: auto;
  cursor: pointer;
  padding: 6px 12px;
  text-align: center;
  gap: 4px;
  border-radius: 12px;
  background: ${({ isActive, theme }) => (isActive ? theme.surface3 : 'transparent')} !important;
  pointer-events: ${({ isActive }) => isActive && 'none'};
  position: relative;
  overflow: hidden;
  transition: background 0.3s ease, box-shadow 0.3s ease;

  ${({ isActive }) =>
    isActive &&
    css`
      background: linear-gradient(to right, #fff 20%, #fff 40%, #87cefa 50%, #87cefa 55%, #fff 70%, #fff 100%);
      background-size: 200% auto;
      animation: ${shineAnimation} 3s linear infinite;
      box-shadow: 0 0 10px rgba(135, 206, 250, 0.5), 0 0 20px rgba(135, 206, 250, 0.3);

      &:before {
        content: '';
        position: absolute;
        top: -50%;
        left: -50%;
        width: 200%;
        height: 200%;
        background: rgba(135, 206, 250, 0.2);
        filter: blur(30px);
        transform: rotate(-45deg);
        z-index: -1;
      }
    `}
`

const RouterSelectSwitch = styled(Row)`
  display: flex;
  padding: 4px;
  border: 1px solid ${({ theme }) => theme.surface3};
  border-radius: 16px;
`

const RouterSelectRoutes = styled.div`
  margin-top: 5px;
  display: flex;
  justify-direction: center;
`

const RouterSelectContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

const SwapButton = styled(ButtonPrimary)`
  margin-top: 30px;
`
const StyledPoweredBy = styled.div`
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
`

const BottomContent = styled(Column)`
  padding-top: 20px;
  margin-top: 10px;
  border-top: 1px solid rgb(67, 61, 83);
`

const DetailRowValue = styled(ThemedText.BodySmall)<{
  warningColor?: keyof DefaultTheme
}>`
  text-align: right;
  overflow-wrap: break-word;
  ${({ warningColor, theme }) => warningColor && `color: ${theme[warningColor]};`};
`

const StyledSwapConfirmation = styled(SwapConfirmation)`
  padding-top: 20px;
  .lh-step-loader {
    background-color: #182035;
  }
  .lh-token-left {
    gap: 3px;
  }
  .lh-token {
    gap: 3px;
  }
`

const Wrapper = styled(Column)`
  border: 1px solid ${({ theme }) => theme.surface3};
  border-radius: 16px;
  padding: 12px 16px;
  margin: 20px;
  margin-bottom: 0px;
  margin-top: 20px;
`

const RotatingArrow = styled(ChevronDown)<{ open?: boolean }>`
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'none')};
  transition: transform 0.1s linear;
`

const StyledTradePrice = styled('button')`
  display: flex;
  align-items: center;
  background: none;
  padding: 0;
  border: none;
  gap: 8px;
  cursor: pointer;
`

const StyledGasIcon = styled(GasIcon)`
  height: 16px;
  width: 16px;
  // We apply the following to all children of the SVG in order to override the default color
  & > * {
    fill: ${({ theme }) => theme.neutral2};
  }
`

interface LiquidityHubSwapModalProps {
  onUserInput: (filed: Field, typedValue: string) => void
  onCurrencySelection: (field: Field, currency: Currency) => void
  priceImpact?: Percent
  fromTokenUsd?: number
  toTokenUsd?: number
}

const SwapModal = ({
  onUserInput,
  onCurrencySelection,
  priceImpact,
  fromTokenUsd,
  toTokenUsd,
}: LiquidityHubSwapModalProps) => {
  const updateBalances = useUpdateBalancesAfterLhSwap()
  const { isOpen, onClose, title, swapStatus, warning } = useSwapConfirmation()
  const { acceptChanges, shouldAccept } = usePriceChanged()
  const theme = useTheme()
  const { chainId } = useWeb3React()

  const onWrapSuccess = useCallback(() => {
    const wToken = chainId && WRAPPED_NATIVE_CURRENCY[chainId]
    wToken && onCurrencySelection(Field.INPUT, wToken)
  }, [chainId, onCurrencySelection])

  const swapButton = useSwapButton(onWrapSuccess)

  const swapCallback = useCallback(async () => {
    try {
      await swapButton.swap()
      updateBalances()
      onUserInput(Field.INPUT, '')
    } catch (error) {
      console.error('Swap failed', error)
    }
  }, [swapButton, updateBalances, onUserInput])

  const topContent = useCallback(() => {
    return <StyledSwapConfirmation fromTokenUsd={fromTokenUsd} toTokenUsd={toTokenUsd} />
  }, [fromTokenUsd, toTokenUsd])

  const bottomContent = useCallback(() => {
    return (
      <Column>
        {swapStatus ? null : shouldAccept ? (
          <SwapShowAcceptChanges data-testid="show-accept-changes">
            <RowBetween>
              <RowFixed>
                <StyledAlertTriangle size={20} />
                <ThemedText.DeprecatedMain color={theme.accent1}>
                  <Trans>Price updated</Trans>
                </ThemedText.DeprecatedMain>
              </RowFixed>
              <SmallButtonPrimary onClick={acceptChanges}>
                <Trans>Accept</Trans>
              </SmallButtonPrimary>
            </RowBetween>
          </SwapShowAcceptChanges>
        ) : (
          <BottomContent>
            <Column gap="md">
              <ExchangeRate />
              <Gas />
              <PriceImpact priceImpact={priceImpact} />
              <MinAmountOut />
            </Column>

            <SwapButton disabled={warning?.isLoading || !!warning} onClick={swapCallback}>
              {warning?.text || swapButton.text}
            </SwapButton>
          </BottomContent>
        )}
        <ConfirmationPoweredBy style={{ marginTop: 30, marginBottom: 10 }} />
      </Column>
    )
  }, [swapStatus, priceImpact, warning, swapCallback, swapButton.text, theme, shouldAccept, acceptChanges])

  return (
    <Modal isOpen={isOpen} onDismiss={onClose}>
      <ConfirmationModalContent
        onDismiss={onClose}
        topContent={topContent}
        title={title}
        bottomContent={bottomContent}
      />
    </Modal>
  )
}

const PoweredByOrbs = ({ style = {} }: { style?: CSSProperties }) => {
  return (
    <StyledPoweredBy style={style}>
      <PoweredBy />
    </StyledPoweredBy>
  )
}

const ExchangeRate = () => {
  const rate = useRate(true)

  return (
    <RowBetween align="flex-start" justify="space-between" gap="sm">
      <RowFixed>
        {' '}
        <Label>
          <Trans>Exchange rate</Trans>
        </Label>
      </RowFixed>

      <DetailRowValue>{`1 ${rate.leftToken} = ${rate.value ?? '-'} ${rate.rightToken}`}</DetailRowValue>
    </RowBetween>
  )
}

const Gas = () => {
  const chainId = useWeb3React().chainId
  const nativeCurrency = useNativeCurrency(chainId)
  const gas = useFormatNumber({ value: useGasCostUsd(), decimalScale: 2 })

  return (
    <RowBetween align="flex-start" justify="space-between" gap="sm">
      <RowFixed>
        <MouseoverTooltip
          text={
            <Trans>
              The fee paid to miners who process your transaction. This must be paid in ${nativeCurrency.symbol}.
            </Trans>
          }
        >
          <Label cursor="help">
            <Plural one="Network fee" other="Network fees" value="" />
          </Label>
        </MouseoverTooltip>
      </RowFixed>
      <DetailRowValue>{gas ? `$${gas}` : '-'}</DetailRowValue>
    </RowBetween>
  )
}

const PriceImpact = ({ priceImpact }: { priceImpact?: Percent }) => {
  const _priceImpact = useFormatNumber({
    value: priceImpact?.multiply(-1).toSignificant(),
    decimalScale: 3,
  })
  return (
    <RowBetween>
      <MouseoverTooltip text={<Trans>The impact your trade has on the market price of this pool.</Trans>}>
        <Label cursor="help">
          <Trans>Price Impact</Trans>
        </Label>
      </MouseoverTooltip>
      <ThemedText.BodySmall>{`${priceImpact ? `${_priceImpact}%` : '-'}`}</ThemedText.BodySmall>
    </RowBetween>
  )
}

const MinAmountOut = () => {
  const quote = useQuote().data
  const minAmountOut = useFormatNumber({ value: quote?.ui.minAmountOut })

  return (
    <RowBetween align="flex-start" justify="space-between" gap="sm">
      <RowFixed>
        <MouseoverTooltip
          text={
            <Trans>
              The minimum amount you are guaranteed to receive. If the price slips any further, your transaction will
              revert.
            </Trans>
          }
        >
          <Label cursor="help">
            <Trans>Minimum output</Trans>
          </Label>
        </MouseoverTooltip>
      </RowFixed>
      <DetailRowValue>{minAmountOut || '-'}</DetailRowValue>
    </RowBetween>
  )
}

const ExpectedAmountOut = () => {
  const quote = useQuote().data
  const expectedAmountOut = useFormatNumber({
    value: quote?.ui.outAmount,
    decimalScale: 2,
  })

  return (
    <RowBetween>
      <RowFixed>
        <MouseoverTooltip
          text={
            <Trans>
              The amount you expect to receive at the current market price. You may receive less or more if the market
              price changes while your transaction is pending.
            </Trans>
          }
        >
          <Label cursor="help">
            <Trans>Expected output</Trans>
          </Label>
        </MouseoverTooltip>
      </RowFixed>
      <DetailRowValue>{expectedAmountOut || '-'}</DetailRowValue>
    </RowBetween>
  )
}

const ClientSwapTradePrice = () => {
  const rate = useRate(true)

  const onTradeClick = (e: any) => {
    e.stopPropagation()
    rate.invert()
  }

  return (
    <StyledTradePrice onClick={onTradeClick}>
      <ThemedText.BodySmall>{`1 ${rate.leftToken} = ${rate.value ?? '-'} ${rate.rightToken}`}</ThemedText.BodySmall>
    </StyledTradePrice>
  )
}

const SwapDetails = ({
  showDetails,
  setShowDetails,
  priceImpact,
  tradePriceComponent,
}: {
  showDetails: boolean
  setShowDetails: (value: boolean) => void
  priceImpact?: Percent
  tradePriceComponent?: React.ReactNode
}) => {
  const quote = useQuote()

  return (
    <Wrapper>
      {quote.isLoading ? (
        <RowFixed>
          <StyledPolling>
            <StyledPollingDot>
              <Spinner />
            </StyledPollingDot>
          </StyledPolling>
          <ThemedText.DeprecatedMain fontSize={14}>
            <Trans>Fetching price from liquidity hub.</Trans>
          </ThemedText.DeprecatedMain>
        </RowFixed>
      ) : BN(quote.data?.outAmount || '0').isZero() ? (
        <RowFixed>
          <ThemedText.DeprecatedMain fontSize={14}>
            <Trans>No liquidity or Route for this trade</Trans>
          </ThemedText.DeprecatedMain>
        </RowFixed>
      ) : (
        <Column>
          <RowFixed style={{ justifyContent: 'space-between', width: '100%' }}>
            {tradePriceComponent}
            <DetailsToggle setShowDetails={setShowDetails} showDetails={showDetails} />
          </RowFixed>
          <AnimatedDropdown open={showDetails}>
            <Column gap="md" style={{ paddingTop: 12 }}>
              <Separator />
              {/* <Gas /> TODO: Fix this for later */}
              <PriceImpact priceImpact={priceImpact} />
              <MinAmountOut />
              <ExpectedAmountOut />
              <Separator />
              <RowBetween>
                <ThemedText.BodySmall color="neutral2">
                  <Trans>Order routing</Trans>
                </ThemedText.BodySmall>
                <DetailRowValue>{LH_ROUTER_LABEL}</DetailRowValue>
              </RowBetween>
            </Column>
          </AnimatedDropdown>
        </Column>
      )}
    </Wrapper>
  )
}

const DetailsToggle = ({
  setShowDetails,
  showDetails,
}: {
  setShowDetails: (value: boolean) => void
  showDetails: boolean
}) => {
  const theme = useTheme()
  const gas = useFormatNumber({ value: useGasCostUsd(), decimalScale: 2 })

  return (
    <RowFixed
      gap="xs"
      style={{ cursor: 'pointer', flex: 1, justifyContent: 'flex-end' }}
      onClick={() => setShowDetails(!showDetails)}
    >
      {/* {!showDetails && (
        <LoadingOpacityContainer $loading={false}>
          <RowFixed gap="xs">
            <StyledGasIcon />
            <ThemedText.BodySmall color="neutral2">
              <Row gap="xs">
                <div>{`$${gas}`}</div>
              </Row>
            </ThemedText.BodySmall>
          </RowFixed>
        </LoadingOpacityContainer>
      )} TODO: FIX THIS*/}
      <RotatingArrow stroke={theme.neutral3} open={Boolean(showDetails)} />
    </RowFixed>
  )
}

export const Provider = ({ children }: { children: ReactNode }) => {
  const { provider, chainId, account } = useWeb3React()
  const toggleWalletDrawer = useToggleAccountDrawer()

  return (
    <LiquidityHubProvider
      chainId={chainId}
      partner="spookyswap"
      provider={provider?.provider}
      supportedChains={[supportedChains.fanton.chainId, 1, 10]}
      connectWallet={toggleWalletDrawer}
      account={account}
      quoteRefetchUntilThrottle={10}
    >
      {children}
    </LiquidityHubProvider>
  )
}

function RouterSelect({
  selected,
  onSelect,
  options,
  outsider,
}: {
  selected: SwapRouterOptions
  onSelect: (option: SwapRouterOptions) => void
  options: SwapRouterOption[]
  outsider?: boolean
}) {
  const storedSelected = sessionStorage.getItem('routerOption')

  return (
    <RouterSelectContainer>
      <Row width="auto">
        <ThemedText.BodySecondary>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Trans>Router</Trans>
            <QuestionHelper
              text={
                <Trans>
                  <b>Auto: </b>Your swap will be routed through the router with the best price for your trade.
                  <br />
                  <br />
                  <b>API: </b>Your swap will be routed through the best swap router.
                  <br />
                  <br />
                  <b>Liquidity Hub: </b>Your swap will be routed through the Orbs router.
                </Trans>
              }
            />
          </div>
        </ThemedText.BodySecondary>
      </Row>

      <RouterSelectRoutes>
        <RouterSelectSwitch>
          {outsider ? (
            <RouterSelectOptionOutsider
              key="best-swap"
              // onClick={() => onSelect(r.value)}
              isActive={true}
            >
              <ThemedText.BodyPrimary fontSize={14}>Best Swap</ThemedText.BodyPrimary>
            </RouterSelectOptionOutsider>
          ) : (
            options.map((r) => (
              <RouterSelectOption
                key={r.value}
                onClick={() => onSelect(r.value)}
                isActive={r.value.toString() === storedSelected}
              >
                <ThemedText.BodyPrimary fontSize={14}>{r.label}</ThemedText.BodyPrimary>
              </RouterSelectOption>
            ))
          )}
        </RouterSelectSwitch>
      </RouterSelectRoutes>
    </RouterSelectContainer>
  )
}

export const LiquidityHub = {
  RouterSelect,
  SwapDetails,
  SwapModal,
  Provider,
  PoweredByOrbs,
  ClientSwapTradePrice,
}
