import { InterfaceElementName } from '@uniswap/analytics-events'
import { DollarSign, Terminal } from 'react-feather'
import styled from 'styled-components'
import { lightTheme } from 'theme/colors'

import { isV3Disabled } from '../../utils/env'
import darkArrowImgSrc from './images/aboutArrowDark.png'
import lightArrowImgSrc from './images/aboutArrowLight.png'
import darkDollarImgSrc from './images/aboutDollarDark.png'
import darkTerminalImgSrc from './images/aboutTerminalDark.png'
import nftCardImgSrc from './images/nftCard.png'
import swapCardImgSrc from './images/swapCard.png'

export const MAIN_CARDS = [
  {
    to: '/swap',
    title: 'Swap tokens',
    description: 'Buy, sell, and explore tokens on SpookySwap.',
    cta: 'Trade Tokens',
    darkBackgroundImgSrc: swapCardImgSrc,
    lightBackgroundImgSrc: swapCardImgSrc,
    elementName: InterfaceElementName.ABOUT_PAGE_SWAP_CARD,
  },
  {
    to: '/nfts',
    title: 'Trade NFTs',
    description: 'Buy and sell NFTs across marketplaces to find more listings at better prices.',
    cta: 'Explore NFTs',
    darkBackgroundImgSrc: nftCardImgSrc,
    lightBackgroundImgSrc: nftCardImgSrc,
    elementName: InterfaceElementName.ABOUT_PAGE_NFTS_CARD,
  },
]

const StyledCardLogo = styled.img`
  min-width: 20px;
  min-height: 20px;
  max-height: 48px;
  max-width: 48px;
`

const V3Disabled = isV3Disabled()
const poolURL = V3Disabled ? '/pools/v2' : '/pools'

export const MORE_CARDS_FTM = [
  {
    // TODO: Point to stakeBoo v2 page
    to: 'https://v2.spooky.fi/#/pools',
    external: true,
    lightIcon: <DollarSign color={lightTheme.neutral3} size={48} />,
    title: 'Stake BOO',
    description: 'Stake BOO for xBOO to claim your portion of trade fees from all DEX trades.',
    darkIcon: <StyledCardLogo src={darkDollarImgSrc} alt="Earn" />,
    cta: 'Stake now',
    elementName: InterfaceElementName.ABOUT_PAGE_BUY_CRYPTO_CARD,
  },
  {
    to: poolURL,
    title: 'Earn',
    description: 'Provide liquidity to pools on SpookySwap and earn fees on swaps.',
    lightIcon: <StyledCardLogo src={lightArrowImgSrc} alt="Analytics" />,
    darkIcon: <StyledCardLogo src={darkArrowImgSrc} alt="Analytics" />,
    cta: 'Provide liquidity',
    elementName: InterfaceElementName.ABOUT_PAGE_EARN_CARD,
  },
  {
    to: '#/bridge',
    external: true,
    lightIcon: <Terminal color={lightTheme.neutral3} size={48} />,
    title: 'Bridge',
    description: 'Bridge and Cross-Chain swap tokens using bridges provided by our partners.',
    darkIcon: <StyledCardLogo src={darkTerminalImgSrc} alt="Developers" />,
    cta: 'Bridge now',
    elementName: InterfaceElementName.ABOUT_PAGE_DEV_DOCS_CARD,
  },
]

export const MORE_CARDS_BTT = [
  {
    // TODO: Point to stakeBoo v2 page
    to: 'https://v2.spooky.fi/#/pools',
    external: true,
    lightIcon: <DollarSign color={lightTheme.neutral3} size={48} />,
    title: 'Stake BOO',
    description: 'Stake BOO for xBOO to claim your portion of trade fees from all DEX trades.',
    darkIcon: <StyledCardLogo src={darkDollarImgSrc} alt="Earn" />,
    cta: 'Stake now',
    elementName: InterfaceElementName.ABOUT_PAGE_BUY_CRYPTO_CARD,
  },
  {
    to: poolURL,
    title: 'Earn',
    description: 'Provide liquidity to pools on SpookySwap and earn fees on swaps.',
    lightIcon: <StyledCardLogo src={lightArrowImgSrc} alt="Analytics" />,
    darkIcon: <StyledCardLogo src={darkArrowImgSrc} alt="Analytics" />,
    cta: 'Provide liquidity',
    elementName: InterfaceElementName.ABOUT_PAGE_EARN_CARD,
  },
  {
    to: 'https://app.bt.io/bridge',
    external: true,
    lightIcon: <Terminal color={lightTheme.neutral3} size={48} />,
    title: 'Bridge',
    description: 'Bridge and Cross-Chain swap tokens using bridges provided by our partners.',
    darkIcon: <StyledCardLogo src={darkTerminalImgSrc} alt="Developers" />,
    cta: 'Bridge now',
    elementName: InterfaceElementName.ABOUT_PAGE_DEV_DOCS_CARD,
  },
]

export const MORE_CARDS_EON = [
  {
    // TODO: Point to stakeBoo v2 page
    to: 'https://v2.spooky.fi/#/pools',
    external: true,
    lightIcon: <DollarSign color={lightTheme.neutral3} size={48} />,
    title: 'Stake BOO',
    description: 'Stake BOO for xBOO to claim your portion of trade fees from all DEX trades.',
    darkIcon: <StyledCardLogo src={darkDollarImgSrc} alt="Earn" />,
    cta: 'Stake now',
    elementName: InterfaceElementName.ABOUT_PAGE_BUY_CRYPTO_CARD,
  },
  {
    to: poolURL,
    title: 'Earn',
    description: 'Provide liquidity to pools on SpookySwap and earn fees on swaps.',
    lightIcon: <StyledCardLogo src={lightArrowImgSrc} alt="Analytics" />,
    darkIcon: <StyledCardLogo src={darkArrowImgSrc} alt="Analytics" />,
    cta: 'Provide liquidity',
    elementName: InterfaceElementName.ABOUT_PAGE_EARN_CARD,
  },
  {
    to: 'https://docs.spooky.fi/',
    external: true,
    lightIcon: <Terminal color={lightTheme.neutral3} size={48} />,
    title: 'Docs & Help',
    description: 'Got any questions or want to learn more?',
    darkIcon: <StyledCardLogo src={darkTerminalImgSrc} alt="Developers" />,
    cta: 'Check out our docs',
    elementName: InterfaceElementName.ABOUT_PAGE_DEV_DOCS_CARD,
  },
]
