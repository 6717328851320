import type { TransactionResponse } from '@ethersproject/providers'
import { useWeb3React } from '@web3-react/core'
import { sendEvent } from 'components/analytics'
import { useCallback } from 'react'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import { calculateGasMargin } from 'utils/calculateGasMargin'

import { useVersionedMasterchef } from './useContract'

export const useAllHarvest = () => {
  const masterChefV3Contract = useVersionedMasterchef(3)
  const { account, chainId } = useWeb3React()
  const addTransaction = useTransactionAdder()

  const handleHarvest = useCallback(async () => {
    if (!account || !masterChefV3Contract || !chainId) return
    return await masterChefV3Contract.estimateGas.harvestAll().then((estimatedGasLimit) => {
      return masterChefV3Contract
        .harvestAll({ value: null, gasLimit: calculateGasMargin(estimatedGasLimit) })
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            type: TransactionType.CLAIM_ALL,
            recipient: account,
            //TODO: calculate farm amount
            //farmAmountRaw: unclaimedAmount?.quotient.toString(),
          })
          sendEvent({
            category: 'Farms',
            action: 'Claim All',
            label: 'claim all farms',
          })
          return response
        })
        .catch((error: any) => {
          // we only care if the error is something _other_ than the user rejected the tx
          if (error?.code !== 4001) {
            console.error(error)
          }
        })
    })
  }, [account, addTransaction, masterChefV3Contract, chainId])

  return { onReward: handleHarvest }
}
