import { ChainId } from '@uniswap/sdk-core'
import { NATIVE_NAMES_BY_ID, USDC_AXL_FANTOM } from '@uniswap/smart-order-router'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { apolloClient } from 'graphql/thegraph/v2apollo'
import { apolloClient as v3ApolloClient } from 'graphql/thegraph/apollo'
import { useDefaultActiveTokens } from 'hooks/Tokens'
import { pricesRawGQL, V2GraphQLToken, tokenPricesRawGQL } from 'hooks/v2/usePriceQueryRaw'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { PriceMap } from 'state/cache/actions'
import { useCacheActionHandlers } from 'state/cache/hooks'

interface PriceFetcherProps {
  onFetchComplete: () => void
}

const NATIVE_NAMES_BY_ID_ADDRESS_INDEX = 2

export const GraphQLPriceFetcher = ({ onFetchComplete }: PriceFetcherProps): JSX.Element => {
  const { chainId } = useWeb3React()
  const defaultTokens = useDefaultActiveTokens(chainId)
  const isFetching = useRef(false)

  const tokenAddresses = useMemo(
    () => (defaultTokens ? Object.keys(defaultTokens).map((k) => k.toLowerCase()) : []),
    [defaultTokens]
  )

  const { onV2GraphQLTokenPricesChange } = useCacheActionHandlers()
  const { onV3GraphQLTokenPricesChange } = useCacheActionHandlers()

  const fetchV3GraphQlPrice = useCallback(async () => {
    if (isFetching.currentV3 || tokenAddresses.length == 0) return // Avoid fetching if already in progress
    isFetching.currentV3 = true
    
    try {
      const result = await v3ApolloClient.query({
        query: tokenPricesRawGQL,
        variables: {
          tokens: tokenAddresses,
        },
        fetchPolicy: 'no-cache',
      })
      

      if (result.data == null || !chainId) return
      const { bundle, tokens } = result.data
      let { ethPriceUSD } = bundle

      const nativeAddress = NATIVE_NAMES_BY_ID[chainId][NATIVE_NAMES_BY_ID_ADDRESS_INDEX].toLowerCase()
      const priceMap: PriceMap = {
        [nativeAddress]: new BigNumber(ethPriceUSD).toNumber(),
      }

      tokens.forEach((token: V2GraphQLToken) => {
        priceMap[token.id] = new BigNumber(token.derivedETH).times(ethPriceUSD).toNumber()
      })

      onV3GraphQLTokenPricesChange(priceMap)
    } catch (e) {
      console.error(e)
    } finally {
      isFetching.currentV3 = false
      onFetchComplete() // Call the fetch complete callback
    }
    
  }, [tokenAddresses, chainId, onV3GraphQLTokenPricesChange, onFetchComplete])
  
  const fetchGraphQLPrice = useCallback(async () => {
    if (isFetching.current || tokenAddresses.length == 0) return // Avoid fetching if already in progress
    isFetching.current = true

    try {
      const result = await apolloClient.query({
        query: pricesRawGQL,
        variables: {
          tokens: tokenAddresses,
          pairs: [],
        },
        fetchPolicy: 'no-cache',
      })

      if (result.data == null || !chainId) return
      const { bundle, tokens } = result.data
      let { ethPrice } = bundle

      if (chainId === ChainId.FANTOM) {
        const usdcAddressLowered = USDC_AXL_FANTOM.address.toLowerCase()
        const axlDerrivedETH = tokens
          .filter((t: V2GraphQLToken) => t.id === usdcAddressLowered)
          .map((t: V2GraphQLToken) => t.derivedETH)
        ethPrice = axlDerrivedETH.length > 0 ? 1 / Number(axlDerrivedETH[0]) : 0.4
      }

      const nativeAddress = NATIVE_NAMES_BY_ID[chainId][NATIVE_NAMES_BY_ID_ADDRESS_INDEX].toLowerCase()
      const priceMap: PriceMap = {
        [nativeAddress]: new BigNumber(ethPrice).toNumber(),
      }

      tokens.forEach((token: V2GraphQLToken) => {
        priceMap[token.id] = new BigNumber(token.derivedETH).times(ethPrice).toNumber()
      })

      onV2GraphQLTokenPricesChange(priceMap)
    } catch (e) {
      console.error(e)
    } finally {
      isFetching.current = false
      onFetchComplete() // Call the fetch complete callback
    }
  }, [tokenAddresses, chainId, onV2GraphQLTokenPricesChange, onFetchComplete])

  useEffect(() => {
    fetchGraphQLPrice()
    fetchV3GraphQlPrice()
  }, [fetchGraphQLPrice, fetchV3GraphQlPrice])

  return <></> // Render nothing; just handle the fetching logic
}
