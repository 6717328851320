import { useMemo } from 'react'
import { isAddress } from 'utils'

import { useCurrency } from './Tokens'

type currencyId = string | undefined

export function usePairAddresstoCurrency(currencyIdA: currencyId, currencyIdB: currencyId) {
  if (!currencyIdA || !currencyIdB) {
    throw new Error('Invalid Farm details route: one of the currency param is undefined')
  }
  const currencyANormalized = useMemo(() => isAddress(currencyIdA) || undefined, [currencyIdA])
  const currencyBNormalized = useMemo(() => isAddress(currencyIdB) || undefined, [currencyIdB])
  const [currencyA, currencyB] = [
    useCurrency(currencyANormalized) ?? undefined,
    useCurrency(currencyBNormalized) ?? undefined,
  ]
  return [currencyA, currencyB]
}
