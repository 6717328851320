import { Trans } from '@lingui/macro'
import { ToggleElement, ToggleWrapper } from 'components/Toggle/MultiToggle'

export default function RangeToggle({
  rangeOption,
  handleRangeToggle,
}: {
  rangeOption: boolean
  handleRangeToggle: () => void
}) {
  const isSorted = rangeOption

  return (
    <div style={{ width: 'fit-content', display: 'flex', alignItems: 'center' }} onClick={handleRangeToggle}>
      <ToggleWrapper width="fit-content">
        <ToggleElement isActive={isSorted} fontSize="12px">
          <Trans>{isSorted ? 'Full' : 'Full'}</Trans>
        </ToggleElement>
        <ToggleElement isActive={!isSorted} fontSize="12px">
          <Trans>{isSorted ? 'Custom' : 'Custom'}</Trans>
        </ToggleElement>
      </ToggleWrapper>
    </div>
  )
}
