import { Trans } from '@lingui/macro'
import styled, { useTheme } from 'styled-components'
import { ThemedText } from 'theme'

import { RowBetween, RowFixed } from '../Row'

const StyledConvertHeader = styled(RowBetween)`
  font-size: 24px;
  // font-weight: 600;
  color: ${({ theme }) => theme.neutral2};
  border-bottom: 1px solid ${({ theme }) => theme.neutral2};
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const HeaderButtonContainer = styled(RowFixed)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 16px;
`

export default function ConvertHeader({ chainId }: { chainId?: number }) {
  const theme = useTheme()

  return (
    <StyledConvertHeader>
      <HeaderButtonContainer>
        <ThemedText.SubHeader color={theme.neutral2} fontSize={16}>
          <Trans>Convert</Trans>
        </ThemedText.SubHeader>
      </HeaderButtonContainer>
    </StyledConvertHeader>
  )
}
