import { BigNumber } from '@ethersproject/bignumber'
import { useCurrency } from 'hooks/Tokens'
import { useGetTID } from 'hooks/useV3Positions'
import { floorFormatter } from 'nft/utils'
import React from 'react'
import styled from 'styled-components'
import { BREAKPOINTS } from 'theme'
import { formatFiatPrice } from 'utils/formatNumbers'

import CurrencyLogo from './Logo/CurrencyLogo'

const DepositsBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px;
  border-radius: 8px;
  align-items: left;
  align-self: center;
  @media screen and (max-width: ${BREAKPOINTS.sm}px) {
    display: none;
  }
`

const DepositRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  gap: 4px;
  font-size: 14px;
  &:last-child {
    margin-bottom: 0;
  }
`

const TokenIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const DepositAmount = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.white};
`

interface TokenIdBalanceProps {
  tokenId: string
}

export function TokenIdBalance({ tokenId }: TokenIdBalanceProps) {
  const { token0, token1, amount0, amount1, usdAmount0, usdAmount1 } = useGetTID(BigNumber.from(tokenId))
  const currency0 = useCurrency(token0?.address)
  const currency1 = useCurrency(token1?.address)
  if (!currency0 || !currency1 || !token0 || !token1) {
    return null
  }

  return (
    <DepositsBox>
      <DepositRow>
        <TokenIcon>
          <CurrencyLogo currency={token0} />
        </TokenIcon>
        <DepositAmount>
          {floorFormatter(Number(amount0))} {token0.symbol}
          <span style={{ marginLeft: '4px', fontSize: '12px', color: '#888' }}>
            ({formatFiatPrice(usdAmount0, 'USD')})
          </span>
        </DepositAmount>
      </DepositRow>
      <DepositRow>
        <TokenIcon>
          <CurrencyLogo currency={token1} />
        </TokenIcon>
        <DepositAmount>
          {floorFormatter(Number(amount1))} {token1.symbol}
          <span style={{ marginLeft: '4px', fontSize: '12px', color: '#888' }}>
            ({formatFiatPrice(usdAmount1, 'USD')})
          </span>
        </DepositAmount>
      </DepositRow>
    </DepositsBox>
  )
}
