import { t, Trans } from '@lingui/macro'
import { BrowserEvent, InterfaceElementName, SharedEventName } from '@uniswap/analytics-events'
import { Pair } from '@uniswap/v2-sdk'
import { Position } from '@uniswap/v3-sdk'
import { useWeb3React } from '@web3-react/core'
import { TraceEvent } from 'analytics'
import { useToggleAccountDrawer } from 'components/AccountDrawer'
import Row from 'components/Row'
import { MouseoverTooltip } from 'components/Tooltip'
import { useFilterPossiblyMaliciousPositions } from 'hooks/useFilterPossiblyMaliciousPositions'
import { useNetworkSupportsV2 } from 'hooks/useNetworkSupportsV2'
import { useSwitchChain } from 'hooks/useSwitchChain'
import JSBI from 'jsbi'
import { EmptyWalletModule } from 'nft/components/profile/view/EmptyWalletContent'
import { useCallback, useMemo, useReducer } from 'react'
import { ChevronsRight } from 'react-feather'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'
import { ThemedText } from 'theme'
import { isV3Disabled } from 'utils/env'
import { formatNumber, NumberType } from 'utils/formatNumbers'

import { ButtonOutlined } from '../../../../components/Button'
import FullPositionCard from '../../../../components/PositionCard'
import { RowFixed } from '../../../../components/Row'
import { Dots } from '../../../../components/swap/styled'
import { BIG_INT_ZERO } from '../../../../constants/misc'
import { useV2Pairs } from '../../../../hooks/useV2Pairs'
import { useTokenBalancesWithLoadingIndicator } from '../../../../state/connection/hooks'
import { useStakingInfo } from '../../../../state/stake/hooks'
import { toV2LiquidityToken, useTrackedTokenPairs } from '../../../../state/user/hooks'
import { ExpandoRow } from '../ExpandoRow'
import { PortfolioLogo } from '../PortfolioLogo'
import PortfolioRow, { PortfolioSkeleton, PortfolioTabWrapper } from '../PortfolioRow'
import { PositionInfo } from './cache'
import { useFeeValues } from './hooks'
import useMultiChainPositions from './useMultiChainPositions'

/**
 * Takes an array of PositionInfo objects (format used by the Uniswap Labs gql API).
 * The hook access PositionInfo.details (format used by the NFT position contract),
 * filters the PositionDetails data for malicious content,
 * and then returns the original data in its original format.
 */

const EmptyProposals = styled.div`
  padding: 16px 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
function useFilterPossiblyMaliciousPositionInfo(positions: PositionInfo[] | undefined): PositionInfo[] {
  const tokenIdsToPositionInfo: Record<string, PositionInfo> = useMemo(
    () =>
      positions
        ? positions.reduce((acc, position) => ({ ...acc, [position.details.tokenId.toString()]: position }), {})
        : {},
    [positions]
  )
  const positionDetails = useMemo(() => positions?.map((position) => position.details) ?? [], [positions])
  const filteredPositionDetails = useFilterPossiblyMaliciousPositions(positionDetails)

  return useMemo(
    () => filteredPositionDetails.map((positionDetails) => tokenIdsToPositionInfo[positionDetails.tokenId.toString()]),
    [filteredPositionDetails, tokenIdsToPositionInfo]
  )
}

export default function Pools({ account }: { account: string }) {
  // v2 pools
  const networkSupportsV2 = useNetworkSupportsV2()

  // fetch the user's balances of all tracked V2 LP tokens
  let trackedTokenPairs = useTrackedTokenPairs()
  if (!networkSupportsV2) trackedTokenPairs = []
  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map((tokens) => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [trackedTokenPairs]
  )
  const liquidityTokens = useMemo(
    () => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken),
    [tokenPairsWithLiquidityTokens]
  )
  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  )

  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  )

  const v2Pairs = useV2Pairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))
  const v2IsLoading =
    fetchingV2PairBalances || v2Pairs?.length < liquidityTokensWithBalances.length || v2Pairs?.some((V2Pair) => !V2Pair)

  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))

  // show liquidity even if its deposited in rewards contract
  //TODO: we need to switch this logic to use the new farms and not staking info which we don't implement
  const stakingInfo = useStakingInfo()
  const stakingInfosWithBalance = stakingInfo?.filter((pool) =>
    JSBI.greaterThan(pool.stakedAmount.quotient, BIG_INT_ZERO)
  )
  const stakingPairs = useV2Pairs(stakingInfosWithBalance?.map((stakingInfo) => stakingInfo.tokens))

  // remove any pairs that also are included in pairs with stake in mining pool
  const v2PairsWithoutStakedAmount = allV2PairsWithLiquidity.filter((v2Pair) => {
    return (
      stakingPairs
        ?.map((stakingPair) => stakingPair[1])
        .filter((stakingPair) => stakingPair?.liquidityToken.address === v2Pair.liquidityToken.address).length === 0
    )
  })

  // v3 pools
  const { positions, loading } = useMultiChainPositions(account)
  const filteredPositions = useFilterPossiblyMaliciousPositionInfo(positions)
  const [showClosed, toggleShowClosed] = useReducer((showClosed) => !showClosed, false)

  const [openPositions, closedPositions] = useMemo(() => {
    const openPositions: PositionInfo[] = []
    const closedPositions: PositionInfo[] = []
    for (let i = 0; i < filteredPositions.length; i++) {
      const position = filteredPositions[i]
      if (position.closed) {
        closedPositions.push(position)
      } else {
        openPositions.push(position)
      }
    }
    return [openPositions, closedPositions]
  }, [filteredPositions])

  const toggleWalletDrawer = useToggleAccountDrawer()
  const theme = useTheme()

  if (!filteredPositions || loading) {
    return <PortfolioSkeleton />
  }

  // if (filteredPositions.length === 0) {
  //   return <EmptyWalletModule type="pool" onNavigateClick={toggleWalletDrawer} />
  // }

  return (
    <PortfolioTabWrapper>
      {v2IsLoading ? (
        <EmptyProposals>
          <ThemedText.DeprecatedBody color={theme.neutral3} textAlign="center">
            <Dots>
              <Trans>Loading</Trans>
            </Dots>
          </ThemedText.DeprecatedBody>
        </EmptyProposals>
      ) : allV2PairsWithLiquidity?.length > 0 || stakingPairs?.length > 0 || openPositions?.length > 0 ? (
        <>
          {/* <ButtonSecondary>
            <RowBetween>
              <Trans>
                <ExternalLink href={'https://v2.info.uniswap.org/account/' + account}>
                  Account analytics and accrued fees
                </ExternalLink>
                <span> ↗ </span>
              </Trans>
            </RowBetween>
          </ButtonSecondary> */}
          {v2PairsWithoutStakedAmount.map((v2Pair) => (
            <FullPositionCard key={v2Pair.liquidityToken.address} pair={v2Pair} bgColor={true} />
          ))}
          {stakingPairs.map(
            (stakingPair, i) =>
              stakingPair[1] && ( // skip pairs that arent loaded
                <FullPositionCard
                  key={stakingInfosWithBalance[i].stakingRewardAddress}
                  pair={stakingPair[1]}
                  stakedBalance={stakingInfosWithBalance[i].stakedAmount}
                  bgColor={true}
                />
              )
          )}
          {!isV3Disabled() && (
            <RowFixed justify="center" style={{ width: '100%' }}>
              <ButtonOutlined
                as={Link}
                to="/migrate/v2"
                id="import-pool-link"
                style={{
                  padding: '8px 16px',
                  margin: '0 4px',
                  borderRadius: '12px',
                  width: 'fit-content',
                  fontSize: '14px',
                }}
              >
                <ChevronsRight size={16} style={{ marginRight: '8px' }} />
                <Trans>Migrate Liquidity to V3</Trans>
              </ButtonOutlined>
            </RowFixed>
          )}
          {openPositions.map((positionInfo) => (
            <PositionListItem
              key={positionInfo.details.tokenId.toString() + positionInfo.chainId}
              positionInfo={positionInfo}
            />
          ))}
          <ExpandoRow
            title={t`Closed Positions`}
            isExpanded={showClosed}
            toggle={toggleShowClosed}
            numItems={closedPositions.length}
          >
            {closedPositions.map((positionInfo) => (
              <PositionListItem
                key={positionInfo.details.tokenId.toString() + positionInfo.chainId}
                positionInfo={positionInfo}
              />
            ))}
          </ExpandoRow>
        </>
      ) : (
        <EmptyWalletModule type="pool" onNavigateClick={toggleWalletDrawer} />
      )}
    </PortfolioTabWrapper>
  )
}

const ActiveDot = styled.span<{ closed: boolean; outOfRange: boolean }>`
  background-color: ${({ theme, closed, outOfRange }) =>
    closed ? theme.neutral2 : outOfRange ? theme.deprecated_accentWarning : theme.success};
  border-radius: 50%;
  height: 8px;
  width: 8px;
  margin-left: 4px;
  margin-top: 1px;
`

function calculcateLiquidityValue(price0: number | undefined, price1: number | undefined, position: Position) {
  if (!price0 || !price1) return undefined

  const value0 = parseFloat(position.amount0.toExact()) * price0
  const value1 = parseFloat(position.amount1.toExact()) * price1
  return value0 + value1
}

function PositionListItem({ positionInfo }: { positionInfo: PositionInfo }) {
  const { chainId, position, pool, details, inRange, closed } = positionInfo

  const { priceA, priceB, fees: feeValue } = useFeeValues(positionInfo)
  const liquidityValue = calculcateLiquidityValue(priceA, priceB, position)

  const navigate = useNavigate()
  const toggleWalletDrawer = useToggleAccountDrawer()
  const { chainId: walletChainId, connector } = useWeb3React()
  const switchChain = useSwitchChain()
  const onClick = useCallback(async () => {
    if (walletChainId !== chainId) await switchChain(connector, chainId)
    toggleWalletDrawer()
    navigate('/pools/' + details.tokenId)
  }, [walletChainId, chainId, switchChain, connector, toggleWalletDrawer, navigate, details.tokenId])
  const analyticsEventProperties = useMemo(
    () => ({
      chain_id: chainId,
      pool_token_0_symbol: pool.token0.symbol,
      pool_token_1_symbol: pool.token1.symbol,
      pool_token_0_address: pool.token0.address,
      pool_token_1_address: pool.token1.address,
    }),
    [chainId, pool.token0.address, pool.token0.symbol, pool.token1.address, pool.token1.symbol]
  )

  return (
    <TraceEvent
      events={[BrowserEvent.onClick]}
      name={SharedEventName.ELEMENT_CLICKED}
      element={InterfaceElementName.MINI_PORTFOLIO_POOLS_ROW}
      properties={analyticsEventProperties}
    >
      <PortfolioRow
        onClick={onClick}
        left={<PortfolioLogo chainId={chainId} currencies={[pool.token0, pool.token1]} />}
        title={
          <Row>
            <ThemedText.SubHeader>
              {pool.token0.symbol} / {pool.token1?.symbol}
            </ThemedText.SubHeader>
          </Row>
        }
        descriptor={<ThemedText.BodySmall>{`${pool.fee / 10000}%`}</ThemedText.BodySmall>}
        right={
          <>
            <MouseoverTooltip
              placement="left"
              text={
                <div style={{ padding: '4px 0px' }}>
                  <ThemedText.BodySmall>{`${formatNumber({
                    input: liquidityValue,
                    type: NumberType.PortfolioBalance,
                  })} (liquidity) + ${formatNumber({
                    input: feeValue,
                    type: NumberType.PortfolioBalance,
                  })} (fees)`}</ThemedText.BodySmall>
                </div>
              }
            >
              <ThemedText.SubHeader>
                {formatNumber({
                  input: (liquidityValue ?? 0) + (feeValue ?? 0),
                  type: NumberType.PortfolioBalance,
                })}
              </ThemedText.SubHeader>
            </MouseoverTooltip>

            <Row justify="flex-end">
              <ThemedText.BodySmall color="neutral2">
                {closed ? t`Closed` : inRange ? t`In range` : t`Out of range`}
              </ThemedText.BodySmall>
              <ActiveDot closed={closed} outOfRange={!inRange} />
            </Row>
          </>
        }
      />
    </TraceEvent>
  )
}
