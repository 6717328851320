import { Trans } from '@lingui/macro'
import { ToggleElement, ToggleWrapper } from 'components/Toggle/MultiToggle'

export default function RewardsTypeToggle({
  rewardsOption,
  handleRewardsTypeToggle,
}: {
  rewardsOption: boolean
  handleRewardsTypeToggle: () => void
}) {
  const isSorted = rewardsOption

  return (
    <div style={{ width: 'fit-content', display: 'flex', alignItems: 'center' }} onClick={handleRewardsTypeToggle}>
      <ToggleWrapper width="fit-content">
        <ToggleElement isActive={isSorted} fontSize="12px">
          <Trans>{isSorted ? 'Total Rewards' : 'Total Rewards'}</Trans>
        </ToggleElement>
        <ToggleElement isActive={!isSorted} fontSize="12px">
          <Trans>{isSorted ? 'Rewards Per Day' : 'Rewards Per Day'}</Trans>
        </ToggleElement>
      </ToggleWrapper>
    </div>
  )
}
