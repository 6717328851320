import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

// Styled components for the console container
const ConsoleContainer = styled.div`
  padding: 15px;
  background-color: #1e1e1e;
  color: #e5e5e5;
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
  border-radius: 8px;
  max-height: 300px; /* Increased height */
  overflow-y: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 10px;
  user-select: text;
  border: 1px solid #333;
`

const ScrollToBottomButton = styled.button`
  background-color: #ffae42;
  color: black;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 12px;
  margin-left: 10px; /* Space between the button and filter */
`

const LogEntry = styled.div<{ type: string }>`
  padding: 5px 0;
  color: ${({ type }) => (type === 'error' ? '#ff5c5c' : type === 'warn' ? '#ffae42' : '#e5e5e5')};
  &:nth-child(even) {
    background-color: #252526;
  }
`

const FilterButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  gap: 10px;
`

const FilterButton = styled.button<{ active: boolean }>`
  background-color: ${({ active }) => (active ? '#ffae42' : '#1e1e1e')};
  color: ${({ active }) => (active ? '#000' : '#fff')};
  border: ${({ active }) => (active ? '2px solid #ffae42' : '2px solid #fff')};
  padding: 8px 15px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 5px;
  transition: background-color 0.2s ease-in-out;
  &:hover {
    background-color: #ffae42;
    color: #000;
  }
`

const ExpandArrow = styled.span<{ expanded: boolean }>`
  cursor: pointer;
  margin-right: 5px;
  display: inline-block;
  transform: rotate(${({ expanded }) => (expanded ? '90deg' : '0deg')});
  transition: transform 0.2s ease-in-out;
`

const MobileConsole = () => {
  const [logs, setLogs] = useState<{ message: any; type: string }[]>([])
  const [pendingLogs, setPendingLogs] = useState<{ message: any; type: string }[]>([])
  const [filter, setFilter] = useState<string>('all')
  const [expandedLogIndices, setExpandedLogIndices] = useState<Set<number>>(new Set())
  const consoleRef = useRef<HTMLDivElement>(null)
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(true)

  useEffect(() => {
    const originalConsoleLog = console.log
    const originalConsoleWarn = console.warn
    const originalConsoleError = console.error

    // Format the log message properly based on type (Array, Error, etc.)
    const handleLog = (args: any[], type: string) => {
      const formattedArgs = args.map((arg) => formatLogMessage(arg)) // Format the log message
      setPendingLogs((prevLogs) => [...prevLogs, { message: formattedArgs, type }])

      if (type === 'log') {
        originalConsoleLog(...args)
      } else if (type === 'warn') {
        originalConsoleWarn(...args)
      } else if (type === 'error') {
        originalConsoleError(...args)
      }
    }

    // Override console methods
    console.log = (...args) => handleLog(args, 'log')
    console.warn = (...args) => handleLog(args, 'warn')
    console.error = (...args) => handleLog(args, 'error')

    return () => {
      console.log = originalConsoleLog
      console.warn = originalConsoleWarn
      console.error = originalConsoleError
    }
  }, [])

  useEffect(() => {
    if (pendingLogs.length > 0) {
      setLogs((prevLogs) => [...prevLogs, ...pendingLogs])
      setPendingLogs([])
    }
  }, [pendingLogs])

  // Auto-scroll to bottom unless the user has scrolled up
  useEffect(() => {
    const consoleElement = consoleRef.current
    if (consoleElement && isScrolledToBottom) {
      consoleElement.scrollTop = consoleElement.scrollHeight
    }
  }, [logs, isScrolledToBottom])

  // Check if the user is scrolled to the bottom
  const handleScroll = () => {
    const consoleElement = consoleRef.current
    if (consoleElement) {
      const isAtBottom = consoleElement.scrollHeight - consoleElement.scrollTop === consoleElement.clientHeight
      setIsScrolledToBottom(isAtBottom)
    }
  }

  // Manually scroll to bottom when the button is clicked
  const scrollToBottom = () => {
    const consoleElement = consoleRef.current
    if (consoleElement) {
      consoleElement.scrollTop = consoleElement.scrollHeight
    }
    setIsScrolledToBottom(true)
  }

  // Toggle expand/collapse for object logs
  const toggleExpand = (index: number) => {
    setExpandedLogIndices((prev) => {
      const newSet = new Set(prev)
      if (newSet.has(index)) {
        newSet.delete(index)
      } else {
        newSet.add(index)
      }
      return newSet
    })
  }

  const filteredLogs = logs.filter((log) => filter === 'all' || log.type === filter)

  const MAX_LOG_LENGTH = 200

  // Helper to format log messages
  const formatLogMessage = (message: any) => {
    if (Array.isArray(message)) {
      return JSON.stringify(message, null, 2)
    } else if (message instanceof Error) {
      return `${message.name}: ${message.message}\n${message.stack}`
    } else if (typeof message === 'object') {
      return JSON.stringify(message, null, 2)
    } else {
      return String(message)
    }
  }

  return (
    <>
      <FilterButtons>
        <FilterButton active={filter === 'all'} onClick={() => setFilter('all')}>
          All
        </FilterButton>
        <FilterButton active={filter === 'warn'} onClick={() => setFilter('warn')}>
          Warnings
        </FilterButton>
        <FilterButton active={filter === 'error'} onClick={() => setFilter('error')}>
          Errors
        </FilterButton>
        {!isScrolledToBottom && <ScrollToBottomButton onClick={scrollToBottom}>Scroll to Bottom</ScrollToBottomButton>}
      </FilterButtons>

      <ConsoleContainer ref={consoleRef} onScroll={handleScroll}>
        {filteredLogs.map((log, index) => (
          <LogEntry key={index} type={log.type}>
            {log.message.map((message: any, msgIndex: number) =>
              typeof message === 'object' ? (
                <div key={msgIndex}>
                  <ExpandArrow expanded={expandedLogIndices.has(index)} onClick={() => toggleExpand(index)}>
                    ▶
                  </ExpandArrow>
                  {expandedLogIndices.has(index) ? (
                    <pre>{JSON.stringify(message, null, 2)}</pre>
                  ) : (
                    <span>
                      {JSON.stringify(message).length > MAX_LOG_LENGTH
                        ? JSON.stringify(message).slice(0, MAX_LOG_LENGTH) + '...'
                        : JSON.stringify(message)}
                    </span>
                  )}
                </div>
              ) : (
                <div key={msgIndex}>
                  {typeof message === 'string' && message.length > MAX_LOG_LENGTH ? (
                    <>
                      <ExpandArrow expanded={expandedLogIndices.has(index)} onClick={() => toggleExpand(index)}>
                        ▶
                      </ExpandArrow>
                      {expandedLogIndices.has(index) ? message : message.slice(0, MAX_LOG_LENGTH) + '...'}
                    </>
                  ) : (
                    message
                  )}
                </div>
              )
            )}
          </LogEntry>
        ))}
      </ConsoleContainer>
    </>
  )
}

export default MobileConsole
