import { gql } from '@apollo/client'
import { useWeb3React } from '@web3-react/core'
import { DEFAULT_CHAIN_ID, SECONDS_IN_DAY } from 'constants/misc'
import dayjs from 'dayjs'
import { Scalars } from 'graphql/data/__generated__/types-and-hooks'
import { apolloClient } from 'graphql/thegraph/v2apollo'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FarmConfig } from 'state/farm/types'

export type V2GraphQLPairDayDatas = {
  readonly __typename?: 'V2GraphQLPairDayDatas'
  readonly id: Scalars['ID']
  readonly pairAddress: Scalars['String']
  readonly dailyVolumeUSD: Scalars['Int']
  readonly reserveUSD: Scalars['Int']
}

const Pair5DayDataGql = (pairs: string[]) => {
  const serializedPairs = JSON.stringify(pairs)
  const fiveDaysAgo = (Math.floor(dayjs().unix() / SECONDS_IN_DAY) - 5) * SECONDS_IN_DAY // EE yesterdays data

  const queryString = `
    query days {
      pairDayDatas(first: 400, where: { pairAddress_in: ${serializedPairs}, date_gt: ${fiveDaysAgo}}) {
        id
        pairAddress
        dailyVolumeUSD
        reserveUSD
        date
      }
    } 
`
  return gql(queryString)
}

const getBulkPair5Data = async (pairs: string[]) => {
  let data
  try {
    const {
      data: { pairDayDatas },
    } = await apolloClient.query({
      query: Pair5DayDataGql(pairs),
      fetchPolicy: 'cache-first',
    })
    data = pairDayDatas
  } catch (e) {
    console.error(e)
  }
  return data
}

export type DayTradeFees = {
  averageVolumeUSD: number
  reserveUSD: number
}
export type AverageDayTradeFeesMap = { [key: string]: DayTradeFees }

export function useGetLP5DayTradeFees(farmConfigs: FarmConfig[]) {
  const { chainId } = useWeb3React()
  const chainIdOrDefault = chainId ?? DEFAULT_CHAIN_ID
  const [prs, setPairs] = useState<V2GraphQLPairDayDatas[]>([])
  const [volumeReservesFiveDayAverageMap, setD] = useState<AverageDayTradeFeesMap>({})
  const farmPairs = useMemo(() => {
    return Array.from(new Set(farmConfigs.filter((f) => chainIdOrDefault == f.chainId).map((x) => x.lpAddress)))
  }, [chainId, farmConfigs])

  const fetchPairs = useCallback(async () => {
    const pairData = await getBulkPair5Data(farmPairs)
    setPairs(pairData)
  }, [getBulkPair5Data, farmPairs])

  useEffect(() => {
    fetchPairs()
  }, [chainId, fetchPairs])

  useMemo(() => {
    const dict: any = {}
    const timestamp = dayjs().unix()
    const beginningOfDay = Math.floor(timestamp / SECONDS_IN_DAY) * SECONDS_IN_DAY
    const totalDays = 4 + (timestamp - beginningOfDay) / SECONDS_IN_DAY

    ;(prs ?? []).forEach(({ pairAddress, dailyVolumeUSD, reserveUSD }, index) => {
      const loweredPairAddress: string = pairAddress.toLowerCase()
      if (dict[loweredPairAddress] == null) {
        dict[loweredPairAddress] = {
          averageVolumeUSD: Number(dailyVolumeUSD),
          reserveUSD,
        }
      } else {
        dict[loweredPairAddress].averageVolumeUSD += Number(dailyVolumeUSD)
        //TODO: this was copied from the old UI, investigate whether this really should this be averaged
        dict[loweredPairAddress].reserveUSD = Number(reserveUSD)
      }
    })

    //TODO: we should convert the average to happen inline with the foreach so we don't need to process after
    Object.keys(dict).forEach((pairAddress) => {
      dict[pairAddress].averageVolumeUSD /= totalDays
    })
    setD(dict)
  }, [prs])

  return volumeReservesFiveDayAverageMap
}

function approxRollingAverage(avg: number, newValue: number, totalNumberOfItems: number) {
  avg -= avg / totalNumberOfItems
  avg += newValue / totalNumberOfItems

  return avg
}
