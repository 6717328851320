import { Trans } from '@lingui/macro'
import { Percent } from '@uniswap/sdk-core'
import styled, { useTheme } from 'styled-components'
import { ThemedText } from 'theme'

import { RowBetween, RowFixed } from '../Row'
import SettingsTab from '../Settings'

const StyledSwapHeader = styled(RowBetween)`
  font-size: 24px;
  // font-weight: 600;
  color: ${({ theme }) => theme.neutral2};
  border-bottom: 1px solid ${({ theme }) => theme.neutral2};
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const HeaderButtonContainer = styled(RowFixed)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  gap: 16px;
`

export default function SwapHeader({
  autoSlippage,
  chainId,
  isUniXTrade,
}: {
  autoSlippage: Percent
  chainId?: number
  isUniXTrade?: boolean
}) {
  const theme = useTheme()
  return (
    <StyledSwapHeader>
      <HeaderButtonContainer>
        <ThemedText.SubHeader color={theme.neutral2} fontSize={16}>
          <Trans>Swap</Trans>
        </ThemedText.SubHeader>
      </HeaderButtonContainer>
      <RowFixed>
        <SettingsTab autoSlippage={autoSlippage} chainId={chainId} isUniXTrade={isUniXTrade} />
      </RowFixed>
    </StyledSwapHeader>
  )
}
