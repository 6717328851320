import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import { ButtonPrimary } from 'components/Button'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import Modal from 'components/Modal'
import { ExternalLinkIcon } from 'components/TokenSafety'
import TokenSafetyIcon from 'components/TokenSafety/TokenSafetyIcon'
import { DEFAULT_CHAIN_ID } from 'constants/misc'
import { checkWarning } from 'constants/tokenSafety'
import { useToken } from 'hooks/Tokens'
import React from 'react'
import styled from 'styled-components'
import { CopyLinkIcon } from 'theme'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
`

const Container = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 32px 24px;
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
`

const InfoText = styled.div`
  display: flex;
  margin-top: 20px;
  font-size: 16px;
  line-height: 24px;
`

const StyledButton = styled(ButtonPrimary)`
  margin-top: 16px;
  width: 100%;
  font-weight: 535;
`

const StyledCancelButton = styled(ButtonPrimary)`
  margin-top: 8px;
  background-color: transparent;
  color: ${({ theme }) => theme.neutral3};
  border: none;
  font-size: 16px;

  &:hover {
    color: ${({ theme }) => theme.neutral2};
    background-color: transparent;
    transition: background-color 250ms ease;
  }

  &:active {
    box-shadow: none;
    border: none;
    background-color: transparent;
  }

  &:focus {
    box-shadow: none;
    border: none;
    background-color: transparent;
  }
`

const ExplorerContainer = styled.div`
  width: 100%;
  height: 32px;
  margin-top: 10px;
  font-size: 20px;
  background-color: ${({ theme }) => theme.accent2};
  color: ${({ theme }) => theme.accent1};
  border-radius: 8px;
  padding: 2px 12px;
  display: flex;
  align-items: center;
  overflow: hidden;
`

const ExplorerLinkWrapper = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;
  cursor: pointer;

  :hover {
    opacity: ${({ theme }) => theme.opacity.hover};
  }
  :active {
    opacity: ${({ theme }) => theme.opacity.click};
  }
`

const ExplorerLink = styled.div`
  display: block;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const WarningContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin-left: 0.3em;
  white-space: nowrap;
  color: ${({ theme }) => theme.neutral3};
  height: 16px;
  margin: 5px;

  @media (max-width: 600px) {
    height: 14px;
    margin: 4px;
  }
`

const ConfirmationModal: React.FC<{
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  tokenAddress: string | null
}> = ({ isOpen, onClose, onConfirm, tokenAddress }) => {
  const { chainId } = useWeb3React()
  const warning = tokenAddress ? checkWarning(tokenAddress) : null
  const token = useToken(tokenAddress)
  const chainIdOrDefault = chainId ?? DEFAULT_CHAIN_ID
  if (!isOpen) return null

  const explorerLink = tokenAddress ? getExplorerLink(chainIdOrDefault, tokenAddress, ExplorerDataType.TOKEN) : ''

  if (!isOpen) return null

  const content = (
    <Wrapper>
      <Container>
        <CurrencyLogo currency={token} size="48px" />
        <WarningContainer>
          Warning
          <TokenSafetyIcon warning={warning} />
        </WarningContainer>
        <InfoText>
          <Trans>Are you sure you want to remove this token?</Trans>
        </InfoText>
        {explorerLink && (
          <ExplorerContainer>
            <ExplorerLinkWrapper onClick={() => window.open(explorerLink, '_blank')}>
              <ExplorerLink>{explorerLink}</ExplorerLink>
              <ExternalLinkIcon />
            </ExplorerLinkWrapper>
            <CopyLinkIcon toCopy={explorerLink} />
          </ExplorerContainer>
        )}
        <StyledButton onClick={onConfirm}>
          <Trans>Remove Token</Trans>
        </StyledButton>
        <StyledCancelButton onClick={onClose}>
          <Trans>Cancel</Trans>
        </StyledCancelButton>
      </Container>
    </Wrapper>
  )

  return (
    <Modal isOpen={isOpen} onDismiss={onClose} height={undefined}>
      {content}
    </Modal>
  )
}

export default ConfirmationModal
