import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import { Exact, Scalars } from 'graphql/data/__generated__/types-and-hooks'

//TODO: this should be generated from the v2 graph ql schema
type PriceRawQuery = {
  readonly __typename?: 'V2GraphQLBundle'
  // readonly __typename?: 'Query',
  readonly bundle: V2GraphQLBundle
  readonly pairs: ReadonlyArray<V2GraphQLPair>
  readonly tokens: ReadonlyArray<V2GraphQLToken>
}

export type V2GraphQLBundle = {
  readonly __typename?: 'V2GraphQLBundle'
  readonly ethPrice: Scalars['Int']
}

export type V2GraphQLToken = {
  readonly __typename?: 'V2GraphQLToken'
  readonly id: Scalars['ID']
  readonly derivedETH: Scalars['Int']
}

export type V2GraphQLPair = {
  readonly __typename?: 'V2GraphQLPair'
  readonly id: Scalars['ID']
  readonly reserveETH: Scalars['Int']
  readonly totalSupply: Scalars['Int']
}

export type PriceRawQueryVariables = Exact<{
  tokens: ReadonlyArray<Scalars['String']> | Scalars['String']
  contracts: ReadonlyArray<Scalars['String']> | Scalars['String']
}>

export const pricesRawGQL = gql`
  query prices_raw($tokens: [Bytes]!, $pairs: [Bytes]!) {
    bundle(id: "1") {
      ethPrice
    }
    pairs(where: { id_in: $pairs }, first: 200) {
      id
      reserveETH
      totalSupply
    }
    tokens(where: { id_in: $tokens }, first: 200) {
      id
      derivedETH
    }
  }
`

export const tokenPricesRawGQL = gql`
  query prices_raw($tokens: [Bytes]!) {
    bundle(id: "1") {
      ethPriceUSD
    }
    tokens(where: { id_in: $tokens }, first: 200) {
      id
      derivedETH
    }
  }
`

// eslint-disable-next-line import/no-unused-modules
export function usePriceRawQuery(options: Apollo.QueryHookOptions<PriceRawQuery, PriceRawQueryVariables>) {
  return Apollo.useQuery<PriceRawQuery, PriceRawQueryVariables>(pricesRawGQL, options)
}
