import { ChainId, Currency, CurrencyAmount, Percent, TradeType } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import Column from 'components/Column'
import { RowBetween, RowFixed } from 'components/Row'
import { BestSwapNewChains } from 'constants/chains'
import { useSpookySwapConfig } from 'hooks/useContract'
import JSBI from 'jsbi'
import { useIsMobile } from 'nft/hooks/useIsMobile'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Layers, Loader, Server } from 'react-feather'
import { IHasQuoteProperties, QuoteMethod } from 'state/routing/types'
import styled, { keyframes, useTheme } from 'styled-components'
import { ThemedText } from 'theme'

import { ClientSideQuoter } from './ClientSideQuoter'
import { LiquidityHubQuoter } from './LiquidityHubQuoter'
import { ParaswapQuoter } from './ParaswapQuoter'
import { useUSDAPIQuote } from './useUSDAPIQuote'

const StyledPolling = styled.div`
  display: flex;
  height: 16px;
  width: 16px;
  margin-right: 2px;
  margin-left: 2px;
  align-items: center;
  color: ${({ theme }) => theme.neutral1};
  transition: 250ms ease color;
`

const StyledPollingDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.surface3};
  transition: 250ms ease background-color;
`

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  animation: ${rotate360} 1s cubic-bezier(0.83, 0, 0.17, 1) infinite;
  width: 14px;
  height: 14px;
  border-left: 2px solid ${({ theme }) => theme.neutral1};
  border-radius: 50%;
`

const ResponsiveWrapper = styled(Column)<{ $isMobile: boolean }>`
  border: 1px solid ${({ theme }) => theme.surface3};
  border-radius: 16px;
  padding: ${({ $isMobile }) => ($isMobile ? '8px 12px' : '12px 16px')};
  margin: ${({ $isMobile }) => ($isMobile ? '12px 8px' : '20px')};
  margin-bottom: 0px;
  margin-top: ${({ $isMobile }) => ($isMobile ? '12px' : '20px')};
`

const ResponsiveHeaderRow = styled(RowBetween)<{ disabled: boolean; open: boolean; $isMobile: boolean }>`
  padding: 0;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
  flex-direction: ${({ $isMobile }) => ($isMobile ? 'column' : 'row')};
  gap: ${({ $isMobile }) => ($isMobile ? '8px' : '0')};
`

const FetchingQuotesRow = styled(RowFixed)<{ $isMobile: boolean }>`
  justify-content: ${({ $isMobile }) => ($isMobile ? 'center' : 'flex-start')};
  width: 100%;
`

const getQuoteMethodIcon = (quoteMethod: QuoteMethod) => {
  switch (quoteMethod) {
    case QuoteMethod.ROUTING_API:
    case QuoteMethod.CLIENT_SIDE:
    case QuoteMethod.CLIENT_SIDE_FALLBACK:
      return <Loader size={16} />
    case QuoteMethod.BEST_TRADE_API:
      return <Server size={16} />
    case QuoteMethod.LIQUIDITY_HUB:
      return <Layers size={16} />
    default:
      return null
  }
}

const getQuoteMethodName = (quoteMethod: QuoteMethod) => {
  switch (quoteMethod) {
    case QuoteMethod.ROUTING_API:
    case QuoteMethod.CLIENT_SIDE:
    case QuoteMethod.CLIENT_SIDE_FALLBACK:
      return 'SpookySwap Router'
    case QuoteMethod.BEST_TRADE_API:
      return 'Best Trade API'
    case QuoteMethod.LIQUIDITY_HUB:
      return 'Liquidity Hub'
    default:
      return 'Unknown Provider'
  }
}

export interface IQuoteSwapParams {
  allowedSlippage: Percent
  inputCurrency: Currency
  outputCurrency: Currency
  inputTax: Percent
  outputTax: Percent
  tradeType: TradeType
  amount: JSBI
}

export enum Quoters {
  CLIENT = 'CLIENT',
  PARASWAP = 'PARASWAP',
  LIQUIDITY_HUB = 'LIQUIDITY_HUB',
}

export interface QuoterProps {
  swapParams: IQuoteSwapParams
  onQuoteUpdate: (quoter: Quoters, quote: IHasQuoteProperties | null) => void
  inputFiatValuePerToken?: number
  outputFiatValuePerToken?: number
}

export function calculateFiatValue(
  tokenPrice: number | undefined,
  amount: CurrencyAmount<Currency>
): number | undefined {
  if (!tokenPrice || !isFinite(tokenPrice)) return undefined

  const normalizedAmount = parseFloat(amount.toExact()) // Use toExact to handle small amounts like 1 wei

  return Number((normalizedAmount * tokenPrice).toFixed(6))
}

export const QuoteManager: React.FC<{
  swapParams: IQuoteSwapParams
  onSelectQuote: (quote?: IHasQuoteProperties) => void
}> = ({ swapParams, onSelectQuote }) => {
  const { inputCurrency, outputCurrency, amount } = swapParams
  const theme = useTheme()
  const isMobile = useIsMobile()

  const inputFiatValuePerToken = useUSDAPIQuote(swapParams.inputCurrency.wrapped)
  const outputFiatValuePerToken = useUSDAPIQuote(swapParams.outputCurrency.wrapped)

  // State to store quotes from different quoters
  const [quotes, setQuotes] = useState<{ [key in Quoters]?: IHasQuoteProperties }>({})
  const [selectedQuoter, setSelectedQuoter] = useState<Quoters | undefined>(undefined)
  const { chainId } = useWeb3React()
  const [isClientSideRoutingEnabled, setIsClientSideRoutingEnabled] = useState(false)

  const isParaswapEnabled = useMemo(() => {
    return chainId === ChainId.FANTOM || (chainId && BestSwapNewChains.includes(chainId)) // Disabled on Fantom, enabled on other chains
  }, [chainId])

  const isLiquidityHubEnabled = useMemo(() => {
    return (
      chainId === ChainId.FANTOM &&
      swapParams.tradeType === TradeType.EXACT_INPUT &&
      swapParams.inputTax.equalTo(0) &&
      swapParams.outputTax.equalTo(0)
    )
  }, [chainId, swapParams.tradeType, swapParams.inputTax, swapParams.outputTax])

  const spookyConfig = useSpookySwapConfig()

  // Check if client-side routing is enabled on the contract
  useEffect(() => {
    const fetchClientSideRoutingStatus = async () => {
      if (chainId === ChainId.FANTOM) {
        if (!spookyConfig) {
          setIsClientSideRoutingEnabled(false)
          return
        }
        try {
          const isEnabled = await spookyConfig.isClientSideSwapEnabled()
          setIsClientSideRoutingEnabled(isEnabled)
        } catch (error) {
          console.error('Error fetching client-side routing status: ', error)
        }
      } else {
        // Client-side routing is always enabled for non-Fantom chains
        setIsClientSideRoutingEnabled(true)
      }
    }

    fetchClientSideRoutingStatus()
  }, [spookyConfig, chainId])

  // Function to enable client-side routing manually (e.g., via window call)
  const enableClientSideRoutingManually = () => {
    setIsClientSideRoutingEnabled(true)
  }

  useEffect(() => {
    // Expose the manual enabling function to the window object
    ;(window as any).enableClientSideRoutingManually = enableClientSideRoutingManually
  }, [])

  // UseMemo for client-side routing logic
  const isClientEnabled = useMemo(() => {
    if (chainId === ChainId.FANTOM) {
      return isClientSideRoutingEnabled
    } else if (chainId && BestSwapNewChains.includes(chainId)) {
      return false
    }

    // On all other chains, client-side routing is enabled by default
    return true
  }, [chainId, isClientSideRoutingEnabled])

  // Memoized logic to determine the best quoter
  const bestQuoter: Quoters | undefined = useMemo(() => {
    if (Object.keys(quotes).length > 0) {
      return Object.keys(quotes).reduce<Quoters | undefined>((best, current) => {
        const currentQuote = quotes[current as Quoters]
        const bestQuote = best ? quotes[best] : undefined

        // If there's no bestQuote yet, set the current one as best
        if (!bestQuote) {
          return current as Quoters
        }

        // Compare executionPrice and return the one with the higher price
        return currentQuote && currentQuote.executionPrice.greaterThan(bestQuote.executionPrice)
          ? (current as Quoters)
          : best
      }, undefined) // Start with no best quoter
    }
    return undefined
  }, [quotes])

  const handleQuoteUpdate = useCallback((quoter: Quoters, quote: IHasQuoteProperties | null) => {
    setQuotes((prevQuotes) => {
      const updatedQuotes = { ...prevQuotes }

      if (quote) {
        updatedQuotes[quoter] = quote // Always override the old quote with the new one
      } else {
        delete updatedQuotes[quoter] // Remove the quote if null
      }

      return updatedQuotes
    })
  }, [])

  useEffect(() => {
    // If there is no explicitly selected quoter, use the best quote for display
    if (!selectedQuoter && bestQuoter && quotes[bestQuoter]) {
      onSelectQuote(quotes[bestQuoter] as IHasQuoteProperties)
    } else if (selectedQuoter && quotes[selectedQuoter]) {
      // If a quoter is explicitly selected, use it
      onSelectQuote(quotes[selectedQuoter] as IHasQuoteProperties)
    }
  }, [bestQuoter, selectedQuoter, quotes, onSelectQuote])

  const selectedQuote = useMemo(() => {
    return selectedQuoter ? quotes[selectedQuoter] : null
  }, [selectedQuoter, quotes])

  useEffect(() => {
    if (selectedQuote) {
      onSelectQuote(selectedQuote as IHasQuoteProperties)
    }
  }, [selectedQuote, onSelectQuote])

  useEffect(() => {
    // Reset the state when swap parameters change
    setQuotes({})
    setSelectedQuoter(undefined) // Reset selection when swap parameters change
  }, [swapParams.inputCurrency, swapParams.outputCurrency, swapParams.tradeType, swapParams.amount])

  if (!inputCurrency || !outputCurrency || !amount) {
    return null
  }

  const handleSelectQuote = (quoter: Quoters, quote: IHasQuoteProperties) => {
    setSelectedQuoter(quoter)
    onSelectQuote(quote) // Pass the selected quote back to the parent
  }

  return (
    <ResponsiveWrapper $isMobile={isMobile}>
      <ResponsiveHeaderRow disabled={Object.keys(quotes).length === 0} open={true} $isMobile={isMobile}>
        {Object.keys(quotes).length === 0 ? (
          <FetchingQuotesRow $isMobile={isMobile}>
            <StyledPolling>
              <StyledPollingDot>
                <Spinner />
              </StyledPollingDot>
            </StyledPolling>
            <ThemedText.DeprecatedMain fontSize={isMobile ? 12 : 14}>Fetching best quotes...</ThemedText.DeprecatedMain>
          </FetchingQuotesRow>
        ) : (
          <RowFixed justify="between" width="100% !important">
            <ThemedText.DeprecatedMain fontSize={isMobile ? 12 : 14}>
              {(() => {
                const selectedQuote = selectedQuoter ? quotes[selectedQuoter] : undefined
                const quoteMethod = selectedQuote?.quoteMethod

                return quoteMethod ? getQuoteMethodName(quoteMethod) : 'Available Providers'
              })()}
            </ThemedText.DeprecatedMain>
          </RowFixed>
        )}
      </ResponsiveHeaderRow>

      <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
        {Object.entries(quotes)
          .sort(([_, quoteA], [__, quoteB]) => {
            if (!quoteA || !quoteB) return 0
            return quoteA.executionPrice.lessThan(quoteB.executionPrice) ? 1 : -1 // Sort in descending order
          })
          .map(([quoter, quote]) => {
            const inputAmount = quote.inputAmount
            const outputAmount = quote.outputAmount
            const executionPrice = quote.executionPrice

            const tradeSummary = `1 ${inputAmount?.currency.symbol} For ${executionPrice.toSignificant(6)} ${
              outputAmount?.currency.symbol
            }`

            return (
              <li
                key={quoter}
                style={{
                  padding: isMobile ? '6px 12px' : '8px 16px',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  gap: isMobile ? '6px' : '8px',
                  backgroundColor: quoter === selectedQuoter ? theme.accent2 : 'transparent',
                  borderRadius: '8px',
                  flexDirection: isMobile ? 'column' : 'row',
                }}
                onClick={() => handleSelectQuote(quoter as Quoters, quote)}
              >
                <div style={{ display: 'flex', alignItems: 'center', gap: '6px', width: '100%' }}>
                  <span>{getQuoteMethodIcon(quote.quoteMethod)}</span>
                  <ThemedText.DeprecatedMain fontSize={isMobile ? 12 : 14}>
                    {getQuoteMethodName(quote.quoteMethod)}
                  </ThemedText.DeprecatedMain>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', flex: 1, width: '100%' }}>
                  <ThemedText.DeprecatedSmall
                    fontSize={isMobile ? 10 : 12}
                  >{`${tradeSummary}`}</ThemedText.DeprecatedSmall>
                </div>
              </li>
            )
          })}
      </ul>

      {/* Render quoters */}
      {isParaswapEnabled && (
        <ParaswapQuoter
          swapParams={swapParams}
          onQuoteUpdate={handleQuoteUpdate}
          inputFiatValuePerToken={inputFiatValuePerToken}
          outputFiatValuePerToken={outputFiatValuePerToken}
        />
      )}
      {isClientEnabled && (
        <ClientSideQuoter
          swapParams={swapParams}
          onQuoteUpdate={handleQuoteUpdate}
          inputFiatValuePerToken={inputFiatValuePerToken}
          outputFiatValuePerToken={outputFiatValuePerToken}
        />
      )}
      {isLiquidityHubEnabled && (
        <LiquidityHubQuoter
          swapParams={swapParams}
          onQuoteUpdate={handleQuoteUpdate}
          inputFiatValuePerToken={inputFiatValuePerToken}
          outputFiatValuePerToken={outputFiatValuePerToken}
        />
      )}
    </ResponsiveWrapper>
  )
}
