import { ComponentProps } from 'react'

export const SwapIcon = (props: ComponentProps<'svg'>) => (
  <svg viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.293 3.293a1 1 0 011.414 0l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414-1.414l.293-.293H6.414l-2 2 2 2H17a1 1 0 110 2H8.414l.293.293a1 1 0 11-1.414 1.414l-5-5a1 1 0 010-1.414l3-3 2-2a1 1 0 011.414 1.414L8.414 11h9.172l2-2-2-2H7a1 1 0 010-2h8.586l-.293-.293a1 1 0 010-1.414z"
    ></path>
  </svg>
)
