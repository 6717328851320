import { useCallback } from 'react'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import { BooLandStake } from 'utils/callhelpers'
import { formatBalance } from 'utils/formatBalance'

import { useBooLand } from './useContract'

export const useBooLandStake = () => {
  const addTransaction = useTransactionAdder()
  const BooLandContract = useBooLand()

  const handleStake = useCallback(
    async (amount: string) => {
      const txHash = BooLandStake(BooLandContract, amount)

      txHash.then((response: any) => {
        const description = `Stake ${formatBalance(Number(amount))} BOO to Mirror World`

        addTransaction(response, {
          type: TransactionType.STAKE_BOO,
          summary: description,
        })
      })
    },
    [BooLandContract, addTransaction]
  )

  return { onStake: handleStake }
}
