import { SwapSide } from '@paraswap/sdk'
import { CurrencyAmount, Token } from '@uniswap/sdk-core'
import { USDC_ON } from '@uniswap/smart-order-router'
import { useWeb3React } from '@web3-react/core'
import { DEFAULT_CHAIN_ID, PARASWAP_PARTNER_ID, PARASWAP_SUPPORTED_CHAINS } from 'constants/misc'
import { useParaswap } from 'hooks/useParaswap'
import { useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { useUSDPrices } from 'state/cache/hooks'

const refreshIntervalMS = 30000
// Cache atom using Jotai's atomWithStorage, persisting data in localStorage
const usdQuoteCacheAtom = atomWithStorage(
  'usdQuoteCache',
  {} as { [key: string]: { value?: number; expiration: number } }
)

// Some tokens in the price map are bad either the price was derrive incorrect from coingecko
// or the price was bad in the rpc/gql
const ignoreTokensFromPriceMap: string[] = []

// Custom hook to fetch and memoize USD value or USDC quote with caching
export const useUSDAPIQuote = (quoteToken?: Token): number | undefined => {
  const paraswap = useParaswap()
  const priceMap = useUSDPrices()
  const { chainId } = useWeb3React()
  const USDC = useMemo(() => USDC_ON(chainId ?? DEFAULT_CHAIN_ID), [chainId])

  // Access the cache atom
  const [cache, setCache] = useAtom(usdQuoteCacheAtom)

  // Function to fetch and resolve the quote
  const fetchUSDQuote = async (): Promise<number | undefined> => {
    if (!paraswap || !quoteToken) return undefined

    const srcToken = USDC.address.toLowerCase()
    const destToken = quoteToken.address.toLowerCase()

    // Check if we have a USD value in the price map
    if (!ignoreTokensFromPriceMap.includes(destToken)) {
      const usdPrice = priceMap?.[destToken] || null
      if (usdPrice) {
        return usdPrice
      }
    }

    // Check the cache for a valid entry
    const now = Date.now()
    const cachedEntry = cache[destToken]
    if (cachedEntry && cachedEntry.expiration > now) {
      return cachedEntry.value
    }

    if (chainId && !PARASWAP_SUPPORTED_CHAINS.includes(chainId)) {
      return
    }

    try {
      // Calculate $100 worth of the destination token based on its decimals
      const amountInUSDToken = CurrencyAmount.fromRawAmount(USDC, (100 * Math.pow(10, USDC.decimals)).toString())

      // Fetch the Paraswap quote for $100 worth of the destination token (USDC)
      const rate = await paraswap.swap.getRate({
        srcToken,
        destToken,
        srcDecimals: USDC.decimals,
        destDecimals: quoteToken.decimals,
        amount: amountInUSDToken.quotient.toString(), // $100 in USDC based on its decimals
        side: SwapSide.SELL,
        options: {
          partner: PARASWAP_PARTNER_ID,
        },
      })

      const quoteAmount = CurrencyAmount.fromRawAmount(quoteToken, rate.destAmount)
      const quoteAmountSignificant = parseFloat(quoteAmount.toSignificant(quoteToken.decimals))
      const quoteValue = parseFloat(rate.srcUSD) / quoteAmountSignificant

      // Update the cache with the new quote and expiration (28 seconds cache)
      setCache({
        ...cache,
        [destToken]: { value: quoteValue, expiration: now + (refreshIntervalMS - 2000) }, // Cache expires a few seconds before refresh
      })

      return quoteValue
    } catch (err) {
      console.error('Failed to fetch API USD Quote rate', err)

      // If the request fails do not try again for until interval refresh
      setCache({
        ...cache,
        [destToken]: { value: undefined, expiration: now + (refreshIntervalMS - 2000) }, // Cache expires a few seconds before refresh
      })
      return undefined
    }
  }

  const { data: quote } = useQuery({
    queryKey: ['fetchUSDQuote', quoteToken?.address],
    queryFn: fetchUSDQuote,
    refetchInterval: refreshIntervalMS,
  })

  // Memoize the returned value to avoid unnecessary recomputation
  return useMemo(() => {
    return quote !== undefined ? quote : undefined
  }, [quote])
}
