import { ComponentProps } from 'react'

export const ConvertIcon = (props: ComponentProps<'svg'>) => (
  <svg
    stroke="none"
    fill="currentColor"
    strokeWidth="2"
    viewBox="0 0 52 52"
    strokeLinecap="round"
    strokeLinejoin="round"
    height="180px"
    width="180px"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M47.9,29.3c0-0.8-0.7-1.5-1.5-1.5h-3.5c-0.8,0-1.4,0.6-1.4,1.4c0,0.2,0,0.5-0.1,0.7
	c-0.3,1.3-0.8,2.5-1.5,3.7c-2.8,4.9-7.7,7.6-12.9,7.6c-4,0-7.8-1.6-10.6-4.4c-0.5-0.4-0.9-0.9-1.2-1.4c-0.3-0.8,0.4-1.2,1.3-1.2h7
	c0.8,0,1.5-0.7,1.5-1.5v-3.1c0-0.8-0.6-1.4-1.4-1.4H5.3c-0.7,0-1.3,0.6-1.3,1.3v18.2c0.1,0.8,0.8,1.5,1.6,1.5h3
	c0.8,0,1.5-0.7,1.5-1.5v-7c0-0.9,0.5-1.3,1.2-0.7c0.3,0.4,0.6,0.7,1,1c5,5,12,7.1,19.2,5.7c2.5-0.5,4.9-1.5,7-2.9
	c5.2-3.4,8.5-8.6,9.4-14.3V29.3z"
    />
    <path
      d="M4.1,22.7c0,0.8,0.7,1.5,1.5,1.5h3.5c0.8,0,1.4-0.6,1.4-1.4c0-0.2,0-0.5,0.1-0.7c0.3-1.3,0.8-2.5,1.5-3.7
	c2.8-4.9,7.7-7.6,12.9-7.6c4,0,7.8,1.6,10.6,4.4c0.5,0.4,0.9,0.9,1.2,1.4c0.3,0.8-0.4,1.2-1.3,1.2h-7c-0.8,0-1.5,0.7-1.5,1.5v3.1
	c0,0.8,0.6,1.4,1.4,1.4h18.3c0.7,0,1.3-0.6,1.3-1.3V4.3c-0.1-0.8-0.8-1.5-1.6-1.5h-3c-0.8,0-1.5,0.7-1.5,1.5v7
	c0,0.9-0.5,1.3-1.2,0.7c-0.3-0.4-0.6-0.7-1-1c-5-5-12-7.1-19.2-5.7c-2.5,0.5-4.9,1.5-7,2.9C8.3,11.6,5,16.8,4.1,22.5L4.1,22.7z"
    />
  </svg>
)
