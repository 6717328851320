import { ComponentProps } from 'react'

export const BridgeIcon = (props: ComponentProps<'svg'>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} className="sc-aXZVg ijawUP" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      d="M10 2H8v2.496c-.992.23-1.99.543-3 .941V3H3v3.33c-.497.247-.996.513-1.5.8l1 1.74c.167-.096.334-.19.5-.28v1.98c-.34.215-.674.441-1 .68V18h6.25A3.91 3.91 0 0 1 12 15.47 3.91 3.91 0 0 1 15.75 18H22v-6.75c-.326-.246-.66-.479-1-.699V8.59c.166.09.333.184.5.28l1-1.74a30.318 30.318 0 0 0-1.5-.8V3h-2v2.437a21.065 21.065 0 0 0-3-.94V2h-2v2.149a17.225 17.225 0 0 0-4 0V2zm9 5.6v1.844a16.753 16.753 0 0 0-3-1.068V6.541A19.98 19.98 0 0 1 19 7.6zm-5-1.455a15.09 15.09 0 0 0-4 0v1.881a17.52 17.52 0 0 1 2.09-.156c.641.007 1.279.05 1.91.13V6.145zM5 7.6a19.98 19.98 0 0 1 3-1.06v1.876a17.52 17.52 0 0 0-3 1.067V7.6zm7.09 2.27A14.4 14.4 0 0 1 20 12.3V16h-3a6 6 0 0 0-5-2.53A6 6 0 0 0 7.05 16H4v-3.68a15.38 15.38 0 0 1 8.09-2.45zm6.47 10.7 1.89 1.26 1.1-1.66-1.88-1.26a3 3 0 0 0-3.34 0l-.77.52a1 1 0 0 1-1.12 0l-.77-.52a3 3 0 0 0-3.34 0l-.77.52a1 1 0 0 1-1.12 0l-.77-.52a3 3 0 0 0-3.34 0l-1.88 1.26 1.1 1.66 1.89-1.26a1 1 0 0 1 1.12 0l.77.52a3 3 0 0 0 3.34 0l.77-.52a1 1 0 0 1 1.12 0l.77.52a3 3 0 0 0 3.34 0l.77-.52a1 1 0 0 1 1.12 0z"
      clipRule="evenodd"
    />
  </svg>
)
