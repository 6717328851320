import { SwapSide } from '@paraswap/sdk'
import { Currency, CurrencyAmount, Price, TradeType } from '@uniswap/sdk-core'
import { PARASWAP_PARTNER_ID } from 'constants/misc'
import { getBestTradeCurrencyAddress, useParaswap } from 'hooks/useParaswap'
import React from 'react'
import { useQuery } from 'react-query'
import { IHasQuoteProperties, QuoteMethod, TradeFillType } from 'state/routing/types'
import { computeFiatValuePriceImpact } from 'utils/computeFiatValuePriceImpact'

import { calculateFiatValue, QuoterProps, Quoters } from './QuoteManager'

// eslint-disable-next-line import/no-unused-modules
export const ParaswapQuoter: React.FC<QuoterProps> = ({
  swapParams,
  onQuoteUpdate,
  inputFiatValuePerToken,
  outputFiatValuePerToken,
}) => {
  const paraswap = useParaswap()

  // Consolidated function to fetch and resolve the quote
  const fetchAndResolveQuote = async () => {
    if (!paraswap) {
      return null
    }

    const srcToken = getBestTradeCurrencyAddress(swapParams.inputCurrency)
    const destToken = getBestTradeCurrencyAddress(swapParams.outputCurrency)
    const srcDecimals = swapParams.inputCurrency.decimals
    const destDecimals = swapParams.outputCurrency.decimals

    try {
      // Fetch the rate from Paraswap
      const rate = await paraswap.swap.getRate({
        srcToken,
        destToken,
        srcDecimals,
        destDecimals,
        amount: swapParams.amount.toString(),
        side: swapParams.tradeType === TradeType.EXACT_INPUT ? SwapSide.SELL : SwapSide.BUY,
        options: {
          includeDEXS: ['SpookySwap', 'SpookySwapV3'],
          maxImpact: 100,
          partner: PARASWAP_PARTNER_ID,
          ignoreBadUsdPrice: true,
          srcTokenTransferFee: swapParams.inputTax?.toSignificant()
            ? (Number(swapParams.inputTax.toSignificant()) * 100).toString()
            : undefined,
          destTokenTransferFee: swapParams.outputTax?.toSignificant()
            ? (Number(swapParams.outputTax.toSignificant()) * 100).toString()
            : undefined,
        },
      })

      // Resolve the optimal rate into a quote
      const resolvedOptimalQuote = resolveQuoteFromOptimalRate(
        rate,
        swapParams.inputCurrency,
        swapParams.outputCurrency,
        inputFiatValuePerToken,
        outputFiatValuePerToken
      )
      const isOptimalQuoteValid = resolvedOptimalQuote.outputAmount && resolvedOptimalQuote.outputAmount.greaterThan(0)

      // Prepare the final quote object
      const quote: IHasQuoteProperties = {
        ...resolvedOptimalQuote,
        isValid: isOptimalQuoteValid,
      }

      return quote
    } catch (err) {
      console.error('Failed to fetch Paraswap rate', err)
      return null
    }
  }

  // Resolving the fetched rate into the IHasQuoteProperties format
  const resolveQuoteFromOptimalRate = (
    rate: any,
    inputCurrency: Currency,
    outputCurrency: Currency,
    inputFiatValuePerToken?: number,
    outputFiatValuePerToken?: number
  ): IHasQuoteProperties => {
    const rateInputAmount = CurrencyAmount.fromRawAmount(inputCurrency, rate.srcAmount)
    const rateOutputAmount = CurrencyAmount.fromRawAmount(outputCurrency, rate.destAmount)

    const internalFiatInputValue = calculateFiatValue(inputFiatValuePerToken, rateInputAmount)
    const paraswapSrc = rate.srcUSD ? Number(rate.srcUSD) : undefined
    const fiatInputValue: number | undefined = internalFiatInputValue ? internalFiatInputValue : paraswapSrc

    const internalFiatOutputValue = calculateFiatValue(outputFiatValuePerToken, rateOutputAmount)
    const paraswapDest = rate.destUSD ? Number(rate.destUSD) : undefined
    const fiatOutputValue: number | undefined = internalFiatOutputValue ? internalFiatOutputValue : paraswapDest

    return {
      inputAmount: rateInputAmount,
      outputAmount: rateOutputAmount,
      executionPrice: new Price(
        rateInputAmount.currency,
        rateOutputAmount.currency,
        rateInputAmount.quotient,
        rateOutputAmount.quotient
      ),
      quote: rate,
      quoteMethod: QuoteMethod.BEST_TRADE_API,
      fillType: TradeFillType.BestSwap,
      tradeType: rate.side === SwapSide.SELL ? TradeType.EXACT_INPUT : TradeType.EXACT_OUTPUT,
      postTaxOutputAmount: rateOutputAmount,
      approveInfo: { needsApprove: false }, // Mocked for now, update if needed
      gasUseEstimateUSD: Number(rate.gasCostUSD),
      priceImpact: computeFiatValuePriceImpact(fiatInputValue, fiatOutputValue), // Use rate.destUSD directly for price impact
      fiatValueInput: { data: Number(fiatInputValue), isLoading: false },
      fiatValueOutput: { data: Number(fiatOutputValue), isLoading: false },
      quoter: Quoters.PARASWAP,
      dependencies: {},
    }
  }

  // useQuery hook to fetch and resolve quotes
  useQuery({
    queryKey: ['fetchOptimalRate', swapParams],
    queryFn: fetchAndResolveQuote, // Directly call fetchAndResolveQuote
    enabled: true,
    refetchInterval: 5000, // Auto-refetch every 5 seconds
    onSuccess: (quote) => {
      if (quote) {
        onQuoteUpdate(Quoters.PARASWAP, quote)
      }
    },
  })

  return null // No rendering necessary
}
