import { useWeb3React } from '@web3-react/core'
import { useSpookySwapConfig } from 'hooks/useContract'
import React, { useCallback, useEffect, useState } from 'react'
import styled, { useTheme } from 'styled-components'

const AdminConfigurationPanel = () => {
  const { account } = useWeb3React() // Get the current account from Web3React
  const spookyConfig = useSpookySwapConfig() // Get the contract instance from your hook
  const [isAdmin, setIsAdmin] = useState<boolean>(false) // State to track if the user is an admin
  const [loading, setLoading] = useState<boolean>(false) // Loading state for button actions
  const [isClientRoutingEnabled, setIsClientRoutingEnabled] = useState<boolean>(false)
  const [newAdminAddress, setNewAdminAddress] = useState<string>('') // Track the input for new admin
  const theme = useTheme() // Access the theme for styled-components

  // Effect to check if the connected account is an admin
  useEffect(() => {
    const checkAdmin = async () => {
      if (!spookyConfig || !account) return

      try {
        const adminRole = await spookyConfig.ADMIN_ROLE()
        const hasAdminRole = await spookyConfig.hasRole(adminRole, account)
        setIsAdmin(hasAdminRole)

        const isEnabled = await spookyConfig.isClientSideSwapEnabled()
        setIsClientRoutingEnabled(isEnabled)
      } catch (error) {
        console.error('Error checking admin role: ', error)
      }
    }

    checkAdmin()
  }, [spookyConfig, account])

  // Unified function to enable/disable client-side routing
  const setClientSideRouting = useCallback(
    async (enabled: boolean) => {
      if (spookyConfig) {
        try {
          setLoading(true)
          const tx = await spookyConfig.setClientSideSwapEnabled(enabled)
          await tx.wait() // Wait for transaction confirmation
          setLoading(false)
          console.log(`Client-side routing ${enabled ? 'enabled' : 'disabled'}`)
        } catch (error) {
          setLoading(false)
          console.error(`Error ${enabled ? 'enabling' : 'disabling'} client-side routing`, error)
        }
      }
    },
    [spookyConfig]
  )

  // Function to assign a new admin
  const addAdmin = useCallback(
    async (newAdmin: string) => {
      if (spookyConfig && newAdmin) {
        try {
          setLoading(true)
          const tx = await spookyConfig.grantAdminRole(newAdmin)
          await tx.wait() // Wait for transaction confirmation
          setLoading(false)
          console.log(`Admin role granted to ${newAdmin}`)
          setNewAdminAddress('') // Reset the input field
        } catch (error) {
          setLoading(false)
          console.error(`Error assigning admin role to ${newAdmin}`, error)
        }
      }
    },
    [spookyConfig]
  )

  // If not an admin, render nothing
  if (!isAdmin) {
    return null
  }

  return (
    <AdminPanelContainer>
      <PanelTitle>Client Side Routing: {isClientRoutingEnabled.toString()}</PanelTitle>
      <ButtonGroup>
        <ActionButton onClick={() => setClientSideRouting(true)} disabled={loading} backgroundColor={theme.success}>
          {loading ? 'Loading...' : 'Enable'}
        </ActionButton>
        <ActionButton onClick={() => setClientSideRouting(false)} disabled={loading} backgroundColor={theme.critical}>
          {loading ? 'Loading...' : 'Disable'}
        </ActionButton>
      </ButtonGroup>

      <PanelTitle>Add New Admin:</PanelTitle>
      <Input
        type="text"
        placeholder="Enter new admin address"
        value={newAdminAddress}
        onChange={(e) => setNewAdminAddress(e.target.value)}
        disabled={loading}
      />
      <ActionButton
        onClick={() => addAdmin(newAdminAddress)}
        disabled={loading || !newAdminAddress}
        backgroundColor={theme.accent1}
      >
        {loading ? 'Assigning...' : 'Add Admin'}
      </ActionButton>
    </AdminPanelContainer>
  )
}

export default AdminConfigurationPanel

// Styled components

const AdminPanelContainer = styled.div`
  border: 2px solid ${({ theme }) => theme.accent1};
  border-radius: 20px;
  padding: 24px;
  background-color: ${({ theme }) => theme.surface2};
  color: ${({ theme }) => theme.neutral2};
  width: 100%;
  max-width: 400px;
  margin: 20px auto 0; /* Add 20px margin-top */
`

const PanelTitle = styled.h3`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.neutral1};
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`

const ActionButton = styled.button<{ backgroundColor: string }>`
  padding: 12px 24px;
  border-radius: 10px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ theme }) => theme.white};
  border: none;
  cursor: pointer;
  font-size: 16px;
  flex: 1;
  text-align: center;
  margin-right: 10px;

  &:hover {
    opacity: 0.9;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &:last-child {
    margin-right: 0;
  }
`

const Input = styled.input`
  padding: 12px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.neutral2};
  border-radius: 10px;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.neutral1};
  background-color: ${({ theme }) => theme.surface1};
`
