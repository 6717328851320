import { CurrencyAmount, Percent, TradeType } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { useDebouncedTrade } from 'hooks/useDebouncedTrade'
import React, { useCallback, useEffect, useMemo } from 'react'
import { ClassicTrade, IHasQuoteProperties, TradeState } from 'state/routing/types'
import { isClassicTrade } from 'state/routing/utils'
import { computeFiatValuePriceImpact } from 'utils/computeFiatValuePriceImpact'
import { computeRealizedPriceImpact } from 'utils/prices'

import { calculateFiatValue, QuoterProps, Quoters } from './QuoteManager'

function largerPercentValue(a?: Percent, b?: Percent) {
  if (a && b) {
    return a.greaterThan(b) ? a : b
  } else if (a) {
    return a
  } else if (b) {
    return b
  }
  return undefined
}

const computePriceImpact = (trade?: ClassicTrade, preTaxStablecoinPriceImpact?: Percent) => {
  if (!trade) return undefined
  const marketPriceImpact = trade.priceImpact ? computeRealizedPriceImpact(trade) : undefined
  return largerPercentValue(marketPriceImpact, preTaxStablecoinPriceImpact)
}

// eslint-disable-next-line import/no-unused-modules
export const ClientSideQuoter: React.FC<QuoterProps> = ({
  swapParams,
  onQuoteUpdate,
  inputFiatValuePerToken,
  outputFiatValuePerToken,
}) => {
  const { inputCurrency, outputCurrency, amount, tradeType, inputTax, outputTax } = swapParams
  const { account } = useWeb3React()

  const tradeCurrency = useMemo(
    () => (tradeType === TradeType.EXACT_INPUT ? inputCurrency : outputCurrency),
    [inputCurrency, outputCurrency, tradeType]
  )

  const parsedAmount = useMemo(() => {
    if (!tradeCurrency) return undefined
    return CurrencyAmount.fromRawAmount(tradeCurrency, amount.toString())
  }, [amount, tradeCurrency])

  const tradeInfo = useDebouncedTrade(
    tradeType,
    parsedAmount,
    tradeType === TradeType.EXACT_INPUT ? outputCurrency : inputCurrency,
    undefined,
    account,
    inputTax,
    outputTax
  )

  const trade = tradeInfo.trade

  const fiatValueInputMemo = useMemo(() => {
    if (!trade?.inputAmount) {
      return { data: undefined, isLoading: false }
    }
    return { data: calculateFiatValue(inputFiatValuePerToken, trade.inputAmount), isLoading: false }
  }, [trade?.inputAmount, inputFiatValuePerToken])

  const fiatValueOutputMemo = useMemo(() => {
    if (!trade?.outputAmount) {
      return { data: undefined, isLoading: false }
    }
    return { data: calculateFiatValue(outputFiatValuePerToken, trade?.outputAmount), isLoading: false }
  }, [trade?.outputAmount, outputFiatValuePerToken])

  const routeIsSyncing = tradeInfo.state === TradeState.LOADING && Boolean(trade)

  const preTaxStablecoinPriceImpact = useMemo(
    () =>
      routeIsSyncing || !isClassicTrade(trade)
        ? undefined
        : computeFiatValuePriceImpact(fiatValueInputMemo?.data, fiatValueOutputMemo?.data),
    [fiatValueInputMemo?.data, fiatValueOutputMemo?.data, routeIsSyncing, trade]
  )

  const resolveQuoteFromTrade = useCallback(
    (trade: ClassicTrade): IHasQuoteProperties => ({
      inputAmount: trade.inputAmount,
      outputAmount: trade.outputAmount,
      executionPrice: trade.executionPrice,
      quote: trade,
      quoteMethod: trade.quoteMethod,
      fillType: trade.fillType,
      tradeType: trade.tradeType,
      postTaxOutputAmount: trade.postTaxOutputAmount,
      approveInfo: trade.approveInfo,
      gasUseEstimateUSD: trade.gasUseEstimateUSD,
      totalGasUseEstimateUSD: trade.totalGasUseEstimateUSD,
      priceImpact: computePriceImpact(trade, preTaxStablecoinPriceImpact),
      fiatValueInput: fiatValueInputMemo,
      fiatValueOutput: fiatValueOutputMemo,
      quoter: Quoters.CLIENT,
      dependencies: { tradeState: tradeInfo.state },
    }),
    [preTaxStablecoinPriceImpact, tradeInfo.state, fiatValueInputMemo, fiatValueOutputMemo]
  )

  // Effect to update the quote when trade updates or fiat values transition from undefined to a value
  useEffect(() => {
    if (tradeInfo.state === TradeState.VALID && tradeInfo?.trade) {
      const newQuote = resolveQuoteFromTrade(tradeInfo.trade)
      onQuoteUpdate(Quoters.CLIENT, newQuote)
    }
  }, [tradeInfo.state, tradeInfo.trade, resolveQuoteFromTrade, onQuoteUpdate])

  return null // This component has no UI, it's just logic
}
