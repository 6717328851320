import { ComponentProps } from 'react'

export const FarmsIcon = (props: ComponentProps<'svg'>) => (
  <svg viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M13 12v2h-2v-2H9v-2h2V8h2v2h2v2h-2z"></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 10a7 7 0 0114 0v8.575c.23.081.454.192.664.332l1.89 1.26-1.109 1.665-1.89-1.26a1 1 0 00-1.11 0l-.78.52a3 3 0 01-3.33 0l-.78-.52a1 1 0 00-1.11 0l-.78.52a3 3 0 01-3.33 0l-.78-.52a1 1 0 00-1.11 0l-1.89 1.26-1.11-1.664 1.89-1.26A3 3 0 015 18.574V10zm2 8.575a3 3 0 01.664.332l.781.521a1 1 0 001.11 0l.78-.52a3 3 0 013.33 0l.78.52a1 1 0 001.11 0l.78-.52c.21-.14.434-.252.665-.333V10a5 5 0 00-10 0v8.575z"
    ></path>
  </svg>
)
