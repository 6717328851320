import { Trans } from '@lingui/macro'
import { ChainId, Currency, Token } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import { RowBetween, RowFixed } from 'components/Row'
import React, { useState } from 'react'
import { Text } from 'rebass'
import { useRemoveUserAddedToken, useUserAddedTokens } from 'state/user/hooks'
import styled from 'styled-components'
import { CloseIcon, ThemedText } from 'theme'
import { UserAddedToken } from 'types/tokens'

import { Separator } from '../styled'
import { TokenTags } from '.'
import ConfirmationModal from './ConfirmationModal'

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  background-color: ${({ theme }) => theme.surface1};
`

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
`

const InfoText = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 20px;
  font-size: 16px;
  line-height: 24px;
`

const StyledCancelButton = styled.div`
  width: 100%;
  border-radius: 20px;
  padding: 5px;
  align-item: center;
  text-align: center;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-color: 'transparent';
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.surface2};
    transition: background-color 250ms ease;
  }
`

const TokenContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 5px;
  margin: 5px;
  align-items: flex-start;
  justify-content: flex-start;
  max-height: 400px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--genie-colors-surface3) transparent;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--genie-colors-surface3);
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: darken(var(--genie-colors-surface3), 10%);
  }
`

const TokenRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 5px;
`

const CurrencyNameContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  text-align: left;
`

const CurrencyLogoContainer = styled.div`
  display: flex;
  height: 100%;
  margin-right: 5px;
  margin-left: 5px;
  justify-content: center;
  align-items: center;
`

const TrashContainer = styled.div`
  display: flex;
  color: white;
  font-size: 24px;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  margin-right: 5px;
  background-color: ${({ theme }) => theme.critical};
  padding: 8px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  z-index: 2;
`

const CurrencyName = styled(Text)`
  padding: 0;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const NoTokensMessage = styled.div`
  width: 100%;
  text-align: center;
  font-size: 16px;
  margin-top: 20px;
`

function isToken(currency: Currency): currency is Token {
  return (currency as Token).address !== undefined
}

const ManageTokens: React.FC<{
  isOpen: boolean
  onCancel: () => void
}> = ({ isOpen, onCancel }) => {
  const { chainId } = useWeb3React()
  const chainIdOrDefault = chainId ?? ChainId.FANTOM
  const addedTokens = useUserAddedTokens()
  const removeToken = useRemoveUserAddedToken()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [tokenToRemove, setTokenToRemove] = useState<Currency | null>(null)

  const handleTrashClick = (e: React.MouseEvent, currency: Currency) => {
    e.stopPropagation()
    setTokenToRemove(currency)
    setIsModalOpen(true)
  }

  const handleRemoveToken = () => {
    if (tokenToRemove && isToken(tokenToRemove)) {
      removeToken(chainIdOrDefault, tokenToRemove.address || '')
    }
    setIsModalOpen(false)
    setTokenToRemove(null)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setTokenToRemove(null)
  }

  if (!isOpen) return null

  return (
    <>
      <Wrapper>
        <Container>
          <RowBetween>
            <InfoText>
              <Text fontWeight={535} fontSize={16}>
                <Trans>Edit My Token List</Trans>
              </Text>
              <CloseIcon onClick={onCancel} />
            </InfoText>
          </RowBetween>
          <Separator />
          <TokenContainer>
            {addedTokens.length === 0 ? (
              <NoTokensMessage>
                <Trans>No custom tokens found</Trans>
              </NoTokensMessage>
            ) : (
              addedTokens.map((token: UserAddedToken, index: number) => (
                <React.Fragment key={`${token.address}-${index}`}>
                  <TokenRow>
                    <CurrencyLogoContainer>
                      <CurrencyLogo currency={token} size="36px" />
                    </CurrencyLogoContainer>
                    <CurrencyNameContainer>
                      <CurrencyName title={token.name}>{token.name}</CurrencyName>
                      <ThemedText.LabelMicro style={{ textAlign: 'left' }} ml="0px">
                        {token.symbol}
                      </ThemedText.LabelMicro>
                    </CurrencyNameContainer>
                    <RowFixed style={{ justifySelf: 'flex-end' }}>
                      <TokenTags currency={token} />
                    </RowFixed>
                    <TrashContainer onClick={(e) => handleTrashClick(e, token)}>-</TrashContainer>
                  </TokenRow>
                </React.Fragment>
              ))
            )}
          </TokenContainer>
        </Container>
        <Separator />
        <StyledCancelButton onClick={onCancel}>
          <Trans>&lt; &nbsp; Selection List</Trans>
        </StyledCancelButton>
      </Wrapper>
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleRemoveToken}
        tokenAddress={tokenToRemove && isToken(tokenToRemove) ? tokenToRemove.address : ''}
      />
    </>
  )
}

export default ManageTokens
