import { BaseButton } from 'components/Button'
import { OpacityHoverState } from 'components/Common'
import Row from 'components/Row'
import { X } from 'react-feather'
import styled from 'styled-components'
import { Z_INDEX } from 'theme/zIndex'

import OldSpook1 from '../../../assets/images/oldspook1.png'

export const PopupContainer = styled.div<{ show: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${({ show }) => !show && 'display: none'};

  background: url(${OldSpook1});
  background-repeat: no-repeat;
  background-position: top 0px right 0px;
  background-size: contain;

  :hover {
    background-size: 220px;
  }
  transition: background-size ${({ theme }) => theme.transition.duration.medium}
    ${({ theme }) => theme.transition.timing.inOut};

  background-color: ${({ theme }) => theme.surface2};
  color: ${({ theme }) => theme.accent1};
  position: fixed;
  z-index: ${Z_INDEX.sticky};

  padding: 24px 16px 16px;

  border-radius: 20px;
  bottom: 50px;
  right: 40px;
  width: 390px;
  height: 164px;

  border: 1px solid ${({ theme }) => theme.surface3};
  border-bottom-right-radius: 0px;

  box-shadow: ${({ theme }) => theme.deprecated_deepShadow};

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    bottom: 62px;
  }

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    background-position: top 0px right 0px;
    width: unset;
    right: 10px;
    left: 10px;
    height: 180px;
  }

  user-select: none;
`

export const ButtonRow = styled(Row)`
  gap: 16px;
`
export const StyledXButton = styled(X)`
  cursor: pointer;
  position: absolute;
  top: 21px;
  right: 17px;

  color: ${({ theme }) => theme.white};
  ${OpacityHoverState};
`

export const BannerButton = styled(BaseButton)`
  height: 40px;
  border-radius: 16px;
  padding: 10px;
  ${OpacityHoverState};
  background-color: ${({ theme }) => theme.accent1};
`

export const BannerText = styled.div`
  color: ${({ theme }) => theme.neutral1};
  font-weight: 550;
`
