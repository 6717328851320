import { Trans } from '@lingui/macro'
import MagicatStakingDecor from 'assets/images/magicat-staking-decor.png'
import PartnerXBooDecor from 'assets/images/partner-pools-decor.png'
import StakeXBooDecor from 'assets/images/stake-xboo-decor.png'
import React from 'react'
import { ChevronRight } from 'react-feather'
import styled from 'styled-components'
import { BREAKPOINTS } from 'theme'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative; /* Make this container relative for absolute positioning inside */
  width: 100%;
  margin: 0 auto;
  overflow: hidden; /* Ensure that overflow content is clipped */
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  // align-items: center;
  // justify-content: center;
  background: ${({ theme }) => theme.surface1};
  // border-radius: 16px;
  // border: 1px solid ${({ theme }) => theme.surface3};
  width: 100vw;
`

const ImageContainer = styled.div`
  position: relative; /* Relative positioning for overlapping */
  width: 100%;
  max-width: 240px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
`

const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 16px;
  display: block; /* Ensure image does not have extra spacing */
`

const Details = styled.div`
  position: absolute;
  top: 6%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${({ theme }) => theme.neutral1};
  padding: 16px;
  width: 80%;
`

const Title = styled.h2`
  font-size: 1.5rem;
  margin: 0;
  color: ${({ theme }) => theme.chain_250};
`

const TitleHeader = styled.h1`
  font-size: 2rem;
  margin: 20px;
  padding: 20px;
`

const Description = styled.p`
  font-size: 1rem;
  margin: 8px 0 0;
  @media screen and (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 0.8rem;
  }
`

const DottedContainer = styled.div<any>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  opacity: ${({ opacity }) => (opacity === 0 ? 0 : 1)};
  padding: 5px;
  margin: 30px;
`

const DottedLine = styled.div<any>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: ${({ width }) => width || '100%'};
  height: 1px;
  border-top: ${({ theme }) => `1px dashed ${theme.neutral1}`};
  border-left: 0;
  border-bottom: 0;
  border-right: 0;
`

const StepsContainer = styled.div<any>`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: ${BREAKPOINTS.md}px) {
    flex-direction: column;
  }
`

const InfoContainer: React.FC = () => {
  const CustomChevron = ChevronRight

  return (
    <Wrapper>
      <Container>
        <TitleHeader>HOW IT WORKS</TitleHeader>
        <StepsContainer>
          <ImageContainer>
            <Image src={StakeXBooDecor} />
            <Details>
              <Title>
                <Trans>STEP 1</Trans>
              </Title>
              <Description>
                <Trans>Stake BOO and receive xBOO</Trans>
              </Description>
            </Details>
          </ImageContainer>
          <DottedContainer>
            <CustomChevron size={12} strokeWidth="2px" color="#F2F4F8" opacity={0} />
            <DottedLine width="24px" />
            <CustomChevron size={12} strokeWidth="2px" color="#F2F4F8" opacity={0} />
          </DottedContainer>
          <ImageContainer>
            <Image src={PartnerXBooDecor} />
            <Details>
              <Title>
                <Trans>STEP 2</Trans>
              </Title>
              <Description>
                <Trans>Stake xBOO in Partner Pools</Trans>
              </Description>
            </Details>
          </ImageContainer>
          <DottedContainer>
            <CustomChevron size={12} strokeWidth="2px" color="#F2F4F8" opacity={0} />
            <DottedLine width="24px" />
            <CustomChevron size={12} strokeWidth="2px" color="#F2F4F8" opacity={1} />
          </DottedContainer>
          <ImageContainer>
            <Image src={MagicatStakingDecor} />
            <Details>
              <Title>
                <Trans>STEP 3</Trans>
              </Title>
              <Description>
                <Trans>(Optional) Use Your Magicats to Boost Pool Earnings</Trans>
              </Description>
            </Details>
          </ImageContainer>
        </StepsContainer>
      </Container>
    </Wrapper>
  )
}

export default InfoContainer
