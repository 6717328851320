import { t } from '@lingui/macro'
import { useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

import { SettingsToggle } from './SettingsToggle'

export const showMobileConsoleAtom = atomWithStorage<boolean>('showMobileconsole', false)

export function MobileConsoleToggle() {
  const [showMobileconsole, updateShowMobileConsole] = useAtom(showMobileConsoleAtom)

  return (
    <SettingsToggle
      title={t`Show Mobile Console`}
      dataid="mobile-console-toggle"
      isActive={showMobileconsole}
      toggle={() => void updateShowMobileConsole((value) => !value)}
    />
  )
}
