import { QuoteResponse, Token, useLiquidityHub, useQuote, zeroAddress } from '@orbs-network/liquidity-hub-ui-sdk'
import { Currency, CurrencyAmount, Percent } from '@uniswap/sdk-core'
import { TokenInfo } from '@uniswap/token-lists'
import { useWeb3React } from '@web3-react/core'
import { usePortfolioBalancesLazyQuery } from 'graphql/data/__generated__/types-and-hooks'
import { GQL_MAINNET_CHAINS } from 'graphql/data/util'
import useTokenLogoSource from 'hooks/useAssetLogoSource'
import { useCallback, useMemo } from 'react'

interface BaseProps {
  allowedSlippage: Percent
  inputAmount?: string
  outputAmount?: string
  inputCurrency?: Currency | null
  outputCurrency?: Currency | null
  isEnabled: boolean
}

export type LiquidityHubResponse = {
  quote?: QuoteResponse
  quoteLoading: boolean
  swap: () => void
  swapLoading: boolean
  swapError?: string
  analyticsInit: () => void
  useQuote: () => any
  outputCurrencyAmount?: CurrencyAmount<Currency>
}

export const useBestTradeLH = (props: BaseProps) => {
  return useLH(props)
}

const useLH = ({
  allowedSlippage,
  inputAmount,
  isEnabled,
  inputCurrency,
  outputCurrency,
}: BaseProps): LiquidityHubResponse => {
  const parsedTokens = useParsedTokens(inputCurrency, outputCurrency)

  const lh = useLiquidityHub({
    fromAmount: inputAmount,
    fromToken: parsedTokens?.fromToken,
    toToken: parsedTokens?.toToken,
    disabled: !isEnabled,
    slippage: Number(allowedSlippage?.toSignificant()),
  })
  const quote = lh.quote

  const outputCurrencyAmount = useMemo(() => {
    if (!outputCurrency || !quote.data?.outAmount) return undefined
    try {
      return CurrencyAmount.fromRawAmount(outputCurrency, quote.data?.outAmount)
    } catch (error) {
      return undefined
    }
  }, [outputCurrency, quote.data?.outAmount])
  return {
    quote: quote.data,
    quoteLoading: quote.isLoading,
    swap: lh.confirmSwap,
    swapLoading: lh.swapLoading,
    swapError: lh.swapError,
    analyticsInit: lh.analyticsInit,
    useQuote,
    outputCurrencyAmount,
  }
}

const useParsedTokens = (
  tokenA?: Currency | null | undefined,
  tokenB?: Currency | null | undefined
): { fromToken: Token; toToken: Token } | undefined => {
  const chainId = useWeb3React().chainId

  const [fromTokenLogo] = useTokenLogoSource(tokenA?.wrapped.address, chainId, tokenA?.isNative)
  const [toTokenLogo] = useTokenLogoSource(tokenB?.wrapped.address, chainId, tokenB?.isNative)
  return useMemo(() => {
    if (!tokenA || !tokenB) return

    return {
      fromToken: {
        address: tokenA.isNative ? zeroAddress : tokenA.wrapped.address,
        decimals: tokenA.decimals,
        symbol: tokenA.symbol || '',
        logoUrl: (tokenA as TokenInfo)?.logoURI || fromTokenLogo,
      },
      toToken: {
        address: tokenB.isNative ? zeroAddress : tokenB.wrapped.address,
        decimals: tokenB.decimals,
        symbol: tokenB.symbol || '',
        logoUrl: (tokenB as TokenInfo)?.logoURI || toTokenLogo,
      },
    }
  }, [tokenA, tokenB, fromTokenLogo, toTokenLogo])
}

export const useUpdateBalancesAfterLhSwap = () => {
  const [prefetchPortfolioBalances] = usePortfolioBalancesLazyQuery()
  const { account } = useWeb3React()

  return useCallback(() => {
    if (!account) return
    prefetchPortfolioBalances({
      variables: { ownerAddress: account, chains: GQL_MAINNET_CHAINS },
    })
  }, [account, prefetchPortfolioBalances])
}
