import { Trans } from '@lingui/macro'
import { ChainId } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { SHORT_SITE_NAME, SITE_NAME } from 'constants/misc'
import { useScreenSize } from 'hooks/useScreenSize'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ThemedText } from 'theme'
import { isMobileSafari } from 'utils/userAgent'

import { BannerButton, BannerText, ButtonRow, PopupContainer, StyledXButton } from './styled'

export default function BaseWalletBanner() {
  const { chainId } = useWeb3React()
  const location = useLocation()

  // Banner changed to be up all time to show old v2 spookyswap site
  const [shouldDisplay, setShouldDisplay] = useState(false)
  const screenSize = useScreenSize()

  if (isMobileSafari) return null

  const subdomainSegmentMap: any = {
    [ChainId.BIT_TORRENT_MAINNET]: 'btt.',
    [ChainId.EON]: 'eon.',
  }

  const url = `https://${chainId ? subdomainSegmentMap[chainId] ?? '' : ''}v2.spooky.fi`
  return (
    <PopupContainer show={shouldDisplay}>
      <StyledXButton
        data-testid="uniswap-wallet-banner"
        size={20}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setShouldDisplay(false)
        }}
      />
      <ThemedText.HeadlineMedium fontSize="24px" lineHeight="28px" maxWidth="250px">
        <Trans>
          Miss the old site?
          <br />
          Go to old {SITE_NAME}.
        </Trans>
      </ThemedText.HeadlineMedium>

      <ButtonRow>
        <BannerButton onClick={() => window.open(url, '_blank')}>
          <ThemedText.LabelSmall marginLeft="5px">
            <BannerText>
              <Trans>Visit old {SHORT_SITE_NAME}</Trans>
            </BannerText>
          </ThemedText.LabelSmall>
        </BannerButton>
      </ButtonRow>
    </PopupContainer>
  )
}
