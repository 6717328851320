import { BigNumber } from '@ethersproject/bignumber'
import { CurrencyAmount, Token } from '@uniswap/sdk-core'
import { Pool, Position } from '@uniswap/v3-sdk'
import { Univ3Staker } from 'abis/types'
import { ZERO_ADDRESS } from 'constants/misc'
import JSBI from 'jsbi'
import { CallStateResult, useSingleCallResult, useSingleContractMultipleData } from 'lib/hooks/multicall'
import { useV3UserPositions } from 'pages/FarmV3/hooks/fetchFarms'
import { useMemo } from 'react'
import { PriceMap } from 'state/cache/actions'
import { useUSDPricesWithFallback } from 'state/cache/hooks'
import { PositionDetails } from 'types/position'

import { useToken } from './Tokens'
import { useV3NFTPositionManagerContract } from './useContract'
import { usePool } from './usePools'

interface UseV3PositionsResults {
  loading: boolean
  positions?: PositionDetails[]
}

export function useV3PositionsFromTokenIds(tokenIds: BigNumber[] | string[] | undefined): UseV3PositionsResults {
  const positionManager = useV3NFTPositionManagerContract()
  const inputs = useMemo(() => (tokenIds ? tokenIds.map((tokenId) => [BigNumber.from(tokenId)]) : []), [tokenIds])
  const results = useSingleContractMultipleData(positionManager, 'positions', inputs)

  const loading = useMemo(() => results.some(({ loading }) => loading), [results])
  const error = useMemo(() => results.some(({ error }) => error), [results])

  const positions = useMemo(() => {
    if (!loading && !error && tokenIds) {
      return results.map((call, i) => {
        const tokenId = tokenIds[i]
        const result = call.result as CallStateResult
        return {
          tokenId,
          fee: result.fee,
          feeGrowthInside0LastX128: result.feeGrowthInside0LastX128,
          feeGrowthInside1LastX128: result.feeGrowthInside1LastX128,
          liquidity: result.liquidity,
          nonce: result.nonce,
          operator: result.operator,
          tickLower: result.tickLower,
          tickUpper: result.tickUpper,
          token0: result.token0,
          token1: result.token1,
          tokensOwed0: result.tokensOwed0,
          tokensOwed1: result.tokensOwed1,
        }
      })
    }
    return undefined
  }, [loading, error, results, tokenIds])

  return {
    loading,
    positions: positions?.map((position, i) => ({ ...position, tokenId: inputs[i][0] })),
  }
}

interface UseV3PositionResults {
  loading: boolean
  position?: PositionDetails
}

export function useV3PositionFromTokenId(tokenId: BigNumber | undefined): UseV3PositionResults {
  const position = useV3PositionsFromTokenIds(tokenId ? [tokenId] : undefined)
  return {
    loading: position.loading,
    position: position.positions?.[0],
  }
}

export function useV3Positions(account: string | null | undefined): UseV3PositionsResults {
  const positionManager = useV3NFTPositionManagerContract()

  const { loading: balanceLoading, result: balanceResult } = useSingleCallResult(positionManager, 'balanceOf', [
    account ?? undefined,
  ])

  // we don't expect any account balance to ever exceed the bounds of max safe int
  const accountBalance: number | undefined = balanceResult?.[0]?.toNumber()

  const tokenIdsArgs = useMemo(() => {
    if (accountBalance && account) {
      const tokenRequests = []
      for (let i = 0; i < accountBalance; i++) {
        tokenRequests.push([account, i])
      }
      return tokenRequests
    }
    return []
  }, [account, accountBalance])

  const tokenIdResults = useSingleContractMultipleData(positionManager, 'tokenOfOwnerByIndex', tokenIdsArgs)
  const someTokenIdsLoading = useMemo(() => tokenIdResults.some(({ loading }) => loading), [tokenIdResults])

  const tokenIds = useMemo(() => {
    if (account) {
      return tokenIdResults
        .map(({ result }) => result)
        .filter((result): result is CallStateResult => !!result)
        .map((result) => BigNumber.from(result[0]))
    }
    return []
  }, [account, tokenIdResults])

  const { positions, loading: positionsLoading } = useV3PositionsFromTokenIds(tokenIds)

  return {
    loading: someTokenIdsLoading || balanceLoading || positionsLoading,
    positions,
  }
}

export function useV3PositionsFromFarm(
  account: string | undefined | null,
  pool: Pool | null | undefined
): UseV3PositionsResults {
  const { positions, loading: positionsLoading } = useV3UserPositions(account ?? undefined)

  const tokenIds = useMemo(() => positions?.map((position) => position.tokenId), [positions])
  //
  const { positions: positionsFromTokenIds } = useV3PositionsFromTokenIds(tokenIds)

  const poolAddressMapping = useMemo(() => {
    if (!positionsFromTokenIds || !pool) {
      return []
    }
    return positionsFromTokenIds.filter(
      (position) =>
        position.token0 === pool.token0.address && position.token1 === pool.token1.address && position.fee === pool.fee
    )
  }, [positionsFromTokenIds, pool])
  const positionsEnrichedStaked = poolAddressMapping?.map((position) => ({
    ...position,
    staked: positions?.some((p) => p.tokenId.toString() === position.tokenId.toString() && p.staked),
  }))

  return {
    loading: positionsLoading,
    positions: positionsEnrichedStaked,
  }
}

export function useV3PositionsWithStakeInfo(
  account: string | undefined | null,
  incentiveKey: string | undefined,
  v3StakerContract: Univ3Staker | null
): UseV3PositionsResults {
  const { positions, loading: positionsLoading } = useV3UserPositions(account ?? undefined)
  const tokenIds = useMemo(() => positions?.map((position) => position.tokenId), [positions])

  const { positions: positionsFromTokenIds } = useV3PositionsFromTokenIds(tokenIds)

  // Fetch deposit information for each position
  const depositCalls = useMemo(() => {
    return tokenIds.map((tokenId) => [tokenId])
  }, [tokenIds])

  const depositResults = useSingleContractMultipleData(v3StakerContract, 'deposits', depositCalls)

  // Fetch stakes information for each position
  const stakesCalls = useMemo(() => {
    return tokenIds.map((tokenId) => [tokenId, incentiveKey])
  }, [tokenIds, incentiveKey])

  const stakesResults = useSingleContractMultipleData(v3StakerContract, 'stakes', stakesCalls)

  const positionsWithStakeInfo = useMemo(() => {
    if (!positionsFromTokenIds) return []

    return positionsFromTokenIds.map((position, index) => {
      const depositResult = depositResults[index]?.result
      // console.log('🚀 ~ returnpositionsFromTokenIds.map ~ depositResult:', depositResult)
      const stakesResult = stakesResults[index]?.result
      // console.log('🚀 ~ returnpositionsFromTokenIds.map ~ stakesResult:', stakesResult)

      const isDeposited = depositResult && depositResult.owner !== ZERO_ADDRESS
      const isStaked = depositResult && BigNumber.from(depositResult.numberOfStakes).gt(0)
      const stakedInIncentive = stakesResult && BigNumber.from(stakesResult.liquidity).gt(0)

      return {
        ...position,
        isDeposited,
        isStaked,
        stakedInIncentive,
      }
    })
  }, [positionsFromTokenIds, depositResults, stakesResults])

  const loading =
    positionsLoading || depositResults.some((call) => call.loading) || stakesResults.some((call) => call.loading)

  return {
    loading,
    positions: positionsWithStakeInfo,
  }
}

// eslint-disable-next-line import/no-unused-modules
export function ToUSD(priceMap: PriceMap, currencyAmount: CurrencyAmount<Token> | undefined) {
  if (!currencyAmount) {
    return 0
  }
  const currencyKey = currencyAmount.currency.address.toLowerCase()
  const tokenPrice = priceMap[currencyKey] ?? 0

  return Number(currencyAmount.toSignificant()) * tokenPrice
}

// Add the new useGetTID hook
export function useGetTID(tokenId: BigNumber | undefined) {
  const { position } = useV3PositionFromTokenId(tokenId)
  const cachedTokenPrices = useUSDPricesWithFallback()

  const token0 = useToken(position?.token0)
  const token1 = useToken(position?.token1)

  const [, pool] = usePool(token0 ?? undefined, token1 ?? undefined, position?.fee)

  const positionSDK = useMemo(() => {
    if (!pool || !position || !token0 || !token1) return null

    return new Position({
      pool,
      liquidity: JSBI.BigInt(position.liquidity.toString()),
      tickLower: position.tickLower,
      tickUpper: position.tickUpper,
    })
  }, [pool, position, token0, token1])

  const { amount0, amount1, usdAmount0, usdAmount1 } = useMemo(() => {
    if (!positionSDK || !token0 || !token1) {
      return { amount0: '0', amount1: '0', usdAmount0: 0, usdAmount1: 0 }
    }

    const amount0 = CurrencyAmount.fromRawAmount(token0, positionSDK.amount0.quotient.toString())
    const amount1 = CurrencyAmount.fromRawAmount(token1, positionSDK.amount1.quotient.toString())

    const usdAmount0 = ToUSD(cachedTokenPrices, amount0)
    const usdAmount1 = ToUSD(cachedTokenPrices, amount1)

    return {
      amount0: amount0.toFixed(2),
      amount1: amount1.toFixed(2),
      usdAmount0,
      usdAmount1,
    }
  }, [positionSDK, token0, token1, cachedTokenPrices])

  return { token0, token1, amount0, amount1, usdAmount0, usdAmount1 }
}
