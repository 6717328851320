import { t, Trans } from '@lingui/macro'
import { ChainId } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import FeatureFlagModal from 'components/FeatureFlagModal/FeatureFlagModal'
import { BridgeIcon } from 'components/Icons/Bridge'
import { FarmsIcon } from 'components/Icons/Farms'
import { StakeBOOIcon } from 'components/Icons/StakeBOO'
import { PrivacyPolicyModal } from 'components/PrivacyPolicy'
import { DISCORD_LINK, GITHUB_LINK } from 'constants/misc'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { Box } from 'nft/components/Box'
import { Column, Row } from 'nft/components/Flex'
import {
  AnalyticsIcon,
  DiscordIconMenu,
  EllipsisIcon,
  GithubIconMenu,
  GovernanceIcon,
  TwitterIconMenu,
} from 'nft/components/icons'
import { body, bodySmall } from 'nft/css/common.css'
import { themeVars } from 'nft/css/sprinkles.css'
import { ReactNode, useReducer, useRef } from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'
import { useToggleModal } from 'state/application/hooks'
import styled, { useTheme } from 'styled-components'
import { isV3FarmDisabled } from 'utils/env'

import { ApplicationModal } from '../../state/application/reducer'
import * as styles from './MenuDropdown.css'
import { NavDropdown } from './NavDropdown'
import { NavIcon } from './NavIcon'

const PrimaryMenuRow = ({
  to,
  href,
  close,
  children,
}: {
  to?: NavLinkProps['to']
  href?: string
  close?: () => void
  children: ReactNode
}) => {
  return (
    <>
      {to ? (
        <NavLink to={to} className={styles.MenuRow}>
          <Row onClick={close}>{children}</Row>
        </NavLink>
      ) : (
        <Row cursor="pointer" as="a" href={href} target="_blank" rel="noopener noreferrer" className={styles.MenuRow}>
          {children}
        </Row>
      )}
    </>
  )
}

const StyledBox = styled(Box)`
  align-items: center;
  display: flex;
  justify-content: center;
`
const PrimaryMenuRowText = ({ children }: { children: ReactNode }) => {
  return <StyledBox className={`${styles.PrimaryText} ${body}`}>{children}</StyledBox>
}

PrimaryMenuRow.Text = PrimaryMenuRowText

const SecondaryLinkedText = ({
  href,
  onClick,
  children,
}: {
  href?: string
  onClick?: () => void
  children: ReactNode
}) => {
  return (
    <Box
      as={href ? 'a' : 'div'}
      href={href ?? undefined}
      target={href ? '_blank' : undefined}
      rel={href ? 'noopener noreferrer' : undefined}
      className={`${styles.SecondaryText} ${bodySmall}`}
      onClick={onClick}
      cursor="pointer"
    >
      {children}
    </Box>
  )
}

const Separator = () => {
  return <Box className={styles.Separator} />
}

const IconRow = ({ children }: { children: ReactNode }) => {
  return <Row className={styles.IconRow}>{children}</Row>
}

const Icon = ({ href, children }: { href?: string; children: ReactNode }) => {
  return (
    <>
      <Box
        as={href ? 'a' : 'div'}
        href={href ?? undefined}
        target={href ? '_blank' : undefined}
        rel={href ? 'noopener noreferrer' : undefined}
        display="flex"
        flexDirection="column"
        color="neutral1"
        background="none"
        border="none"
        justifyContent="center"
        textAlign="center"
        marginRight="12"
      >
        {children}
      </Box>
    </>
  )
}

export const MenuDropdown = () => {
  const theme = useTheme()
  const { chainId } = useWeb3React()
  const [isOpen, toggleOpen] = useReducer((s) => !s, false)
  const togglePrivacyPolicy = useToggleModal(ApplicationModal.PRIVACY_POLICY)
  const openFeatureFlagsModal = useToggleModal(ApplicationModal.FEATURE_FLAGS)
  const ref = useRef<HTMLDivElement>(null)
  const isFtm = chainId === ChainId.FANTOM
  const isBtt = chainId === ChainId.BIT_TORRENT_MAINNET
  const isEon = chainId === ChainId.EON
  const isBera = chainId === ChainId.BERA_TESTNET
  const isSonicTestnet = chainId === ChainId.SONIC_TESTNET
  const isAlternateChain = isEon || isBera || isSonicTestnet

  // TODO: change farms url when ready
  const isFarmV3Disabled = true
  const farmsUrl = isV3FarmDisabled() ? '/farms/v2' : '/farms/v3'
  useOnClickOutside(ref, isOpen ? toggleOpen : undefined)

  // TODO: removing v2 analytics, wait for v3
  // const url = `https://info.${chainId ? subdomainSegmentMap[chainId] ?? '' : ''}spooky.fi`
  const bridgeUrl = '/bridge'

  return (
    <>
      <Box position="relative" ref={ref} marginRight="4">
        <NavIcon isActive={isOpen} onClick={toggleOpen} label={isOpen ? t`Show resources` : t`Hide resources`}>
          <EllipsisIcon viewBox="0 0 20 20" width={24} height={24} />
        </NavIcon>

        {isOpen && (
          <NavDropdown top={{ sm: 'unset', lg: '56' }} bottom={{ sm: '50', lg: 'unset' }} right="0">
            <Column gap="16">
              <Column paddingX="8" gap="4">
                {isFtm && (
                  <>
                    <Box display={{ sm: 'none', lg: 'none', xxl: 'none' }}>
                      <PrimaryMenuRow to={farmsUrl} close={toggleOpen}>
                        <Icon>
                          <FarmsIcon width={24} height={24} fill={theme.neutral1} />
                        </Icon>
                        <PrimaryMenuRow.Text>
                          <Trans>Farms V2</Trans>
                        </PrimaryMenuRow.Text>
                      </PrimaryMenuRow>
                    </Box>
                    <Box></Box>
                    <Box display="block">
                      <PrimaryMenuRow href="https://v3.info.spooky.fi/#/fantom" close={toggleOpen}>
                        <Icon>
                          <AnalyticsIcon width={24} height={24} color={theme.neutral1} />
                        </Icon>
                        <PrimaryMenuRow.Text>
                          <Trans>Analytics</Trans>
                        </PrimaryMenuRow.Text>
                      </PrimaryMenuRow>
                    </Box>
                    <Box display="block">
                      <PrimaryMenuRow href="https://snapshot.org/#/spookyswap.eth/" close={toggleOpen}>
                        <Icon>
                          <GovernanceIcon width={24} height={24} color={theme.neutral1} />
                        </Icon>
                        <PrimaryMenuRow.Text>
                          <Trans>Vote</Trans>
                        </PrimaryMenuRow.Text>
                      </PrimaryMenuRow>
                    </Box>
                    {/* <PrimaryMenuRow href={url}>
                      <Icon>
                        <BarChartIcon width={24} height={24} color={theme.neutral1} />
                      </Icon>
                      <PrimaryMenuRow.Text>
                        <Trans>View more analytics</Trans>
                      </PrimaryMenuRow.Text>
                    </PrimaryMenuRow> */}
                    {window.innerWidth <= 875 && (
                      <PrimaryMenuRow to={bridgeUrl} close={toggleOpen}>
                        <Icon>
                          <BridgeIcon width={24} height={24} fill={theme.neutral1} />
                        </Icon>
                        <PrimaryMenuRow.Text>
                          <Trans>Bridge</Trans>
                        </PrimaryMenuRow.Text>
                      </PrimaryMenuRow>
                    )}
                    <Box display={{ sm: 'flex', lg: 'none', xxl: 'none' }}>
                      <PrimaryMenuRow href="/#/stake-boo" close={toggleOpen}>
                        <Icon>
                          <StakeBOOIcon width={24} height={24} fill={theme.neutral1} />
                        </Icon>
                        <PrimaryMenuRow.Text>
                          <Trans>Stake BOO</Trans>
                        </PrimaryMenuRow.Text>
                      </PrimaryMenuRow>
                    </Box>
                  </>
                )}
                {isBtt && (
                  <>
                    <Box display={{ sm: 'none', lg: 'none', xxl: 'none' }}>
                      <PrimaryMenuRow to={farmsUrl} close={toggleOpen}>
                        <Icon>
                          <FarmsIcon width={24} height={24} fill={theme.neutral1} />
                        </Icon>
                        <PrimaryMenuRow.Text>
                          <Trans>Farms V2</Trans>
                        </PrimaryMenuRow.Text>
                      </PrimaryMenuRow>
                    </Box>
                    <Box display="block">
                      <PrimaryMenuRow href="https://v3.info.spooky.fi/#/bittorent" close={toggleOpen}>
                        <Icon>
                          <AnalyticsIcon width={24} height={24} color={theme.neutral1} />
                        </Icon>
                        <PrimaryMenuRow.Text>
                          <Trans>Analytics</Trans>
                        </PrimaryMenuRow.Text>
                      </PrimaryMenuRow>
                    </Box>

                    <Box display="block">
                      <PrimaryMenuRow href="https://snapshot.org/#/spookyswap.eth/" close={toggleOpen}>
                        <Icon>
                          <GovernanceIcon width={24} height={24} color={theme.neutral1} />
                        </Icon>
                        <PrimaryMenuRow.Text>
                          <Trans>Vote</Trans>
                        </PrimaryMenuRow.Text>
                      </PrimaryMenuRow>
                    </Box>
                    {/* <PrimaryMenuRow href={url}>
                      <Icon>
                        <BarChartIcon width={24} height={24} color={theme.neutral1} />
                      </Icon>
                      <PrimaryMenuRow.Text>
                        <Trans>View more analytics</Trans>
                      </PrimaryMenuRow.Text>
                    </PrimaryMenuRow> */}
                    <Box display={{ sm: 'flex', lg: 'none', xxl: 'none' }}>
                      <PrimaryMenuRow href="https://app.bt.io/bridge" close={toggleOpen}>
                        <Icon>
                          <BridgeIcon width={24} height={24} fill={theme.neutral1} />
                        </Icon>
                        <PrimaryMenuRow.Text>
                          <Trans>Bridge</Trans>
                        </PrimaryMenuRow.Text>
                      </PrimaryMenuRow>
                    </Box>
                  </>
                )}
                {isAlternateChain && (
                  <>
                    <Box display={{ sm: 'none', lg: 'none', xxl: 'none' }}>
                      <PrimaryMenuRow to={farmsUrl} close={toggleOpen}>
                        <Icon>
                          <FarmsIcon width={24} height={24} fill={theme.neutral1} />
                        </Icon>
                        <PrimaryMenuRow.Text>
                          <Trans>Farms V2</Trans>
                        </PrimaryMenuRow.Text>
                      </PrimaryMenuRow>
                    </Box>
                    <Box display="block">
                      <PrimaryMenuRow href="https://v3.info.spooky.fi/#/eon" close={toggleOpen}>
                        <Icon>
                          <AnalyticsIcon width={24} height={24} color={theme.neutral1} />
                        </Icon>
                        <PrimaryMenuRow.Text>
                          <Trans>Analytics</Trans>
                        </PrimaryMenuRow.Text>
                      </PrimaryMenuRow>
                    </Box>
                    <Box display="block">
                      <PrimaryMenuRow href="https://snapshot.org/#/spookyswap.eth/" close={toggleOpen}>
                        <Icon>
                          <GovernanceIcon width={24} height={24} color={theme.neutral1} />
                        </Icon>
                        <PrimaryMenuRow.Text>
                          <Trans>Vote</Trans>
                        </PrimaryMenuRow.Text>
                      </PrimaryMenuRow>
                    </Box>
                    {/* <PrimaryMenuRow href={url}>
                      <Icon>
                        <BarChartIcon width={24} height={24} color={theme.neutral1} />
                      </Icon>
                      <PrimaryMenuRow.Text>
                        <Trans>View more analytics</Trans>
                      </PrimaryMenuRow.Text>
                    </PrimaryMenuRow> */}
                  </>
                )}
              </Column>
              <Separator />
              <Box
                display="flex"
                flexDirection={{ sm: 'row', md: 'column' }}
                flexWrap="wrap"
                alignItems={{ sm: 'center', md: 'flex-start' }}
                paddingX="8"
                whiteSpace="nowrap"
              >
                <SecondaryLinkedText href={DISCORD_LINK}>
                  <Trans>Support</Trans> ↗
                </SecondaryLinkedText>
                <SecondaryLinkedText href="https://docs.spooky.fi/">
                  <Trans>Documentation</Trans> ↗
                </SecondaryLinkedText>

                {/* {(isDevelopmentEnv() || isStagingEnv()) && (
                  <SecondaryLinkedText
                    onClick={() => {
                      toggleOpen()
                      openFeatureFlagsModal()
                    }}
                  >
                    <Trans>Feature Flags</Trans>
                  </SecondaryLinkedText>
                )} */}
              </Box>
              <IconRow>
                <Icon href={DISCORD_LINK}>
                  <DiscordIconMenu className={styles.hover} width={24} height={24} color={themeVars.colors.neutral2} />
                </Icon>
                <Icon href="https://twitter.com/Spookyswap">
                  <TwitterIconMenu className={styles.hover} width={24} height={24} color={themeVars.colors.neutral2} />
                </Icon>
                <Icon href={GITHUB_LINK}>
                  <GithubIconMenu className={styles.hover} width={24} height={24} color={themeVars.colors.neutral2} />
                </Icon>
              </IconRow>
            </Column>
          </NavDropdown>
        )}
      </Box>
      <PrivacyPolicyModal />
      <FeatureFlagModal />
    </>
  )
}
