import FarmListJSON from 'constants/farms/default_spooky_farms.json'
import { atom, useAtom } from 'jotai'
import React, { useEffect } from 'react'
import { useFetchFarms } from 'state/farm/hooks'
import { Farm } from 'state/farm/types'

// eslint-disable-next-line import/no-unused-modules
export const farmPricesAtom = atom<Farm[]>([])

export const FarmPriceFetcherComponent: React.FC<{ onFetchComplete: () => void }> = ({ onFetchComplete }) => {
  const { isLoading, isError, fetchedFarmData } = useFetchFarms(FarmListJSON.farms)
  const [, setFarmPrices] = useAtom(farmPricesAtom)

  // Fetch and set farm prices
  useEffect(() => {
    if (!isLoading && !isError && fetchedFarmData) {
      // Update farm prices atom
      setFarmPrices(fetchedFarmData as Farm[]) // Ensure the type matches the Farm type
      // Notify parent that farm prices have been fetched
      onFetchComplete()
    }
  }, [isLoading, isError, fetchedFarmData, setFarmPrices, onFetchComplete])

  return <></> // Render nothing
}
