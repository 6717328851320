import { ChainId } from '@uniswap/sdk-core'

type Features = {
  isV2FarmsSupported: boolean
}
type SupportedChainFeatureMap = { readonly [chainId: number]: Features }

export const SUPPORTED_CHAIN_FEATURES: SupportedChainFeatureMap = {
  [ChainId.ARBITRUM_GOERLI]: {
    isV2FarmsSupported: false,
  },
  [ChainId.ARBITRUM_ONE]: {
    isV2FarmsSupported: false,
  },
  [ChainId.AVALANCHE]: {
    isV2FarmsSupported: false,
  },
  [ChainId.BASE]: {
    isV2FarmsSupported: false,
  },
  [ChainId.BASE_GOERLI]: {
    isV2FarmsSupported: false,
  },
  [ChainId.BERA_TESTNET]: {
    isV2FarmsSupported: false,
  },
  [ChainId.BIT_TORRENT_MAINNET]: {
    isV2FarmsSupported: true,
  },
  [ChainId.BNB]: {
    isV2FarmsSupported: false,
  },
  [ChainId.CELO]: {
    isV2FarmsSupported: false,
  },
  [ChainId.CELO_ALFAJORES]: {
    isV2FarmsSupported: false,
  },
  [ChainId.EON]: {
    isV2FarmsSupported: true,
  },
  [ChainId.FANTOM]: {
    isV2FarmsSupported: true,
  },
  [ChainId.GNOSIS]: {
    isV2FarmsSupported: false,
  },
  [ChainId.GOERLI]: {
    isV2FarmsSupported: false,
  },
  [ChainId.MAINNET]: {
    isV2FarmsSupported: false,
  },
  [ChainId.MOONBEAM]: {
    isV2FarmsSupported: false,
  },
  [ChainId.OPTIMISM]: {
    isV2FarmsSupported: false,
  },
  [ChainId.OPTIMISM_GOERLI]: {
    isV2FarmsSupported: false,
  },
  [ChainId.POLYGON]: {
    isV2FarmsSupported: false,
  },
  [ChainId.POLYGON_MUMBAI]: {
    isV2FarmsSupported: false,
  },
  [ChainId.SEPOLIA]: {
    isV2FarmsSupported: false,
  },
  [ChainId.SONIC_TESTNET]: {
    isV2FarmsSupported: false,
  },
} as const
