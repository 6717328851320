import { ComponentProps } from 'react'

export const StakeBOOIcon = (props: ComponentProps<'svg'>) => (
  <svg viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.757 7.483l.363.246c.612.263 1.472.04 2.111-.6.653-.652.871-1.533.583-2.147l-.227-.325-.002-.002c-.586-.586-1.694-.427-2.475.354-.78.78-.94 1.888-.354 2.474zM7.999 3.24l.002.003c.311.312.534.66.684 1.023l10.456 15.66L3.42 9.604a3.134 3.134 0 01-1.077-.706c-.837-.838-1.032-1.945-.901-2.862.13-.916.588-1.775 1.255-2.442.667-.667 1.526-1.124 2.442-1.255.916-.13 2.023.064 2.86.901H8zm-.353 5.304c.342-.342.629-.735.845-1.16l4.373 6.265-6.329-4.285a4.59 4.59 0 001.11-.82zM20.96 6.393l-1 5-1.96-.393 1-5 1.96.393zM3.33 17l6 1L9 19.973l-6-1L3.33 17z"
    ></path>
  </svg>
)
