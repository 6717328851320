import { TransactionRequest } from '@ethersproject/providers'
import { SwapSide } from '@paraswap/sdk'
import { Fraction } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { BEST_SWAP_NAME, BIG_INT_ONE, PARASWAP_PARTNER_FTM_ADDRESS, PARASWAP_PARTNER_ID, PARASWAP_FEE_BIPS } from 'constants/misc'
import { useCallback } from 'react'
import { IHasTradeProperties, TradeFillType } from 'state/routing/types'
import { isQuoteOptimalRate } from 'state/routing/utils'
import { useUserSlippageTolerance } from 'state/user/hooks'
import { SlippageTolerance } from 'state/user/types'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import { UserRejectedRequestError, WrongChainError } from 'utils/errors'
import { didUserReject, swapErrorToUserReadableMessage } from 'utils/swapErrorToUserReadableMessage'
import { MAXIMUM_RECOMMENDED_SLIPPAGE } from 'utils/validateUserSlippageTolerance'

import useENS from './useENS'
import { useParaswap } from './useParaswap'
import { ModifiedSwapError } from './useUniversalRouter'

// const transaction = {
//   ...txParams,
//   gasPrice: '0x' + BigNumber.from(txParams.gasPrice).toString(16),
//   gasLimit: '0x' + new BigNumber(5000000).toString(16),
//   value: '0x' + new BigNumber(txParams.value).toString(16),
// }
const convertToEthersTransaction = (txParams: any): TransactionRequest => {
  return {
    to: txParams.to,
    from: txParams.from,
    data: txParams.data,
    chainId: txParams.chainId,
    // gasLimit: calculateGasMargin(BigNumber.from(txParams.gas)),
    value: txParams.value,
    // maxFeePerGas: txParams.gasPrice,
    // maxPriorityFeePerGas: parseUnits('2', 'gwei'),
  }
}

// eslint-disable-next-line import/no-unused-modules
export function useParaswapCallback(
  trade: IHasTradeProperties | null | undefined,
  recipientAddressOrName: string | null
) {
  const { account, chainId, provider } = useWeb3React()

  const paraswap = useParaswap()
  const [allowedSlippage] = useUserSlippageTolerance()
  const userSlippage = allowedSlippage == SlippageTolerance.Auto ? MAXIMUM_RECOMMENDED_SLIPPAGE : allowedSlippage

  const { address: recipientAddress } = useENS(recipientAddressOrName)

  return useCallback(async () => {
    try {
      if (!account) throw new Error('missing account')
      if (!chainId) throw new Error('missing chainId')
      if (!provider) throw new Error('missing provider')
      if (!trade || !trade.quote) throw new Error('missing optimal rate')
      if (!isQuoteOptimalRate(trade.quote)) throw new Error('trade is not optimal rate')
      const connectedChainId = await provider.getSigner().getChainId()
      if (chainId !== connectedChainId) throw new WrongChainError()

      const recipient = recipientAddress ?? account
      const partnerAddress = PARASWAP_PARTNER_FTM_ADDRESS
      const partnerFeeBps = PARASWAP_FEE_BIPS;

      const referrer = PARASWAP_PARTNER_ID
      const srcToken = trade.quote.srcToken
      const destToken = trade.quote.destToken
      const minDestAmount =
        trade.quote.side === SwapSide.BUY
          ? trade.quote.destAmount
          : new Fraction(BIG_INT_ONE)
              .add(userSlippage)
              .invert()
              .multiply(trade.outputAmount.quotient)
              .quotient.toString()

      const maxSrcAmount =
        trade.quote.side === SwapSide.BUY
          ? new Fraction(BIG_INT_ONE).add(userSlippage).multiply(trade.inputAmount.quotient).quotient.toString()
          : trade.quote.srcAmount

      let txParams

      try {
        if (!paraswap) {
          throw new Error('Could not resolve paraswap ')
        }

        txParams = await paraswap.swap.buildTx({
          srcToken,
          destToken,
          srcAmount: maxSrcAmount,
          destAmount: minDestAmount,
          priceRoute: trade.quote,
          userAddress: account,
          receiver: recipient,
          partner: referrer,
          partnerAddress,
          takeSurplus: true,
          partnerFeeBps,
        })
      } catch (e) {
        console.error(e)
        throw new Error(
          `For rebase or taxed tokens, try client side routing in settings instead of ${BEST_SWAP_NAME}. Ensure that you are using the correct slippage.`
        )
      }

      // Estimate gas

      const tx = convertToEthersTransaction(txParams)
      const estimate = await provider.getSigner().estimateGas(tx)

      const txWithGasEstimate = {
        ...tx,
        gasLimit: calculateGasMargin(estimate),
      }

      const response = await provider
        .getSigner()
        .sendTransaction(txWithGasEstimate)
        .then((response) => {
          if (tx.data !== response.data) {
            if (!response.data || response.data.length === 0 || response.data === '0x') {
              throw new ModifiedSwapError()
            }
          }
          return response
        })
      return {
        type: TradeFillType.Classic as const,
        response,
      }
    } catch (swapError: unknown) {
      if (swapError instanceof ModifiedSwapError) throw swapError

      // GasEstimationErrors are already traced when they are thrown.
      // if (!(swapError instanceof GasEstimationError)) setTraceError(swapError)

      // Cancellations are not failures, and must be accounted for as 'cancelled'.
      if (didUserReject(swapError)) {
        // This error type allows us to distinguish between user rejections and other errors later too.
        throw new UserRejectedRequestError(swapErrorToUserReadableMessage(swapError))
      }

      throw new Error(swapErrorToUserReadableMessage(swapError))
    }
  }, [account, chainId, trade, paraswap, provider, recipientAddress, userSlippage])
}
