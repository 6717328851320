import { atom, useAtom } from 'jotai'
import { debounce } from 'lodash'
import { useEffect } from 'react'
import { useRef } from 'react'

// Import the CoinGecko token ID map from the JSON file
import rawCoinGeckoTokenIdMap from './consts/coin-gecko-token-id.json' // Load the CoinGecko token IDs

// Define the map's type
interface CoinGeckoTokenIdMap {
  [key: string]: string | undefined // key is a token address, value is the CoinGecko ID or undefined
}

// Cast the imported JSON to the CoinGeckoTokenIdMap type, handling potential mismatches
const tokenIdMap: CoinGeckoTokenIdMap = rawCoinGeckoTokenIdMap as unknown as CoinGeckoTokenIdMap

// Configuration for cache expiration and refresh intervals
const CACHE_EXPIRATION_TIMEOUT = 60000 // 5 seconds

// Define the atom for caching token prices and timestamps by token address
interface TokenPriceCache {
  price: number
  timestamp: number
}

export const tokenPriceCacheAtom = atom<Record<string, TokenPriceCache>>({})

// Define a type for the response from CoinGecko when fetching the token prices
interface CoinGeckoPriceResponse {
  [key: string]: {
    usd?: number
  }
}

// Function to fetch multiple token prices from CoinGecko
const fetchMultipleTokenPrices = async (tokenIds: string[]): Promise<Record<string, number> | undefined> => {
  if (!tokenIds || tokenIds.length === 0) return undefined

  try {
    const priceUrl = `https://api.coingecko.com/api/v3/simple/price?ids=${tokenIds.join(',')}&vs_currencies=usd`
    const priceResponse = await fetch(priceUrl)
    const priceData: CoinGeckoPriceResponse = await priceResponse.json()

    // Map the fetched prices by CoinGecko token ID
    const prices: Record<string, number> = {}
    for (const tokenId of tokenIds) {
      const usdValue = priceData[tokenId]?.usd
      if (usdValue) {
        prices[tokenId] = usdValue
      }
    }

    return prices
  } catch {
    return undefined
  }
}

// Token price fetching component
interface CoinGeckoPriceFetcherProps {
  onFetchComplete: () => void // Callback to notify when fetching is complete
  tokenAddresses: string[] // List of tokens to fetch prices for
}

export const CoinGeckoPriceFetcher = ({ onFetchComplete, tokenAddresses }: CoinGeckoPriceFetcherProps): JSX.Element => {
  const [tokenPriceCache, setTokenPriceCache] = useAtom(tokenPriceCacheAtom)
  const isFetching = useRef(false)

  useEffect(() => {
    const fetchPrices = debounce(async () => {
      // Prevent fetching if already in progress
      if (isFetching.current) return
      isFetching.current = true

      const currentTimestamp = Date.now()
      const addressesToFetch: string[] = []
      const validCachedPrices: Record<string, number> = {}

      // Check cache and prepare the list of CoinGecko IDs to fetch prices for
      for (const tokenAddress of tokenAddresses.map((f) => f.toLowerCase())) {
        const cachedData = tokenPriceCache[tokenAddress]
        if (cachedData && currentTimestamp - cachedData.timestamp < CACHE_EXPIRATION_TIMEOUT) {
          validCachedPrices[tokenAddress] = cachedData.price // Use cached price
        } else {
          const coinGeckoId = tokenIdMap[tokenAddress]
          if (coinGeckoId) {
            addressesToFetch.push(coinGeckoId) // Fetch price for CoinGecko ID
          }
        }
      }

      // Fetch prices for CoinGecko IDs that need to be fetched
      if (addressesToFetch.length > 0) {
        const fetchedPrices = await fetchMultipleTokenPrices(addressesToFetch)

        if (fetchedPrices) {
          setTokenPriceCache((prevCache) => ({
            ...prevCache,
            ...Object.fromEntries(
              Object.entries(fetchedPrices).map(([coinGeckoId, price]) => {
                // Find the corresponding token address for this CoinGecko ID
                const tokenAddress = Object.keys(tokenIdMap).find((key) => tokenIdMap[key] === coinGeckoId)
                return [tokenAddress, { price, timestamp: currentTimestamp }]
              })
            ),
          }))
        }
      }

      // Notify parent component that the fetching is complete
      onFetchComplete()

      isFetching.current = false // Reset fetching state
    }, 300) // Debounce delay of 300ms

    fetchPrices() // Trigger the debounced price fetching on mount
  }, [tokenAddresses, tokenPriceCache, setTokenPriceCache, onFetchComplete])

  return <></> // Render nothing; just handle fetching logic
}
