import { AddLiquiditySkeleton } from 'components/addLiquidity/AddLiquiditySkeleton'
import { ArrowLeft } from 'react-feather'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { textFadeIn } from 'theme/styles'

import { LoadingBubble } from '../../Tokens/loading'
import { BreadcrumbNavLink } from '../../Tokens/TokenDetails/BreadcrumbNavLink'

const LP_COMPONENT_WIDTH = 360

const Hr = styled.hr`
  background-color: ${({ theme }) => theme.surface3};
  border: none;
  height: 0.5px;
`
const TokenDetailsLayout = styled.div`
  display: flex;
  padding: 0 8px 52px;
  justify-content: center;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.sm}px) {
    gap: 16px;
    padding: 0 16px 52px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    gap: 40px;
    padding: 48px 20px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.xl}px) {
    gap: 60px;
  }
`
const LeftPanel = styled.div`
  flex: 1;
  max-width: 780px;
  overflow: hidden;
`
const RightPanel = styled.div`
  display: none;
  flex-direction: column;
  gap: 20px;
  width: ${LP_COMPONENT_WIDTH}px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.lg}px) {
    display: flex;
  }
`
const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 436px;
  margin-bottom: 24px;
  align-items: flex-start;
  width: 100%;
`
const TokenInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  ${textFadeIn};
  animation-duration: ${({ theme }) => theme.transition.duration.medium};
`
const TokenNameCell = styled.div`
  display: flex;
  gap: 8px;
  font-size: 20px;
  line-height: 28px;
  align-items: center;
`
/* Loading state bubbles */
const DetailBubble = styled(LoadingBubble)`
  height: 16px;
  width: 180px;
`
const SquaredBubble = styled(DetailBubble)`
  height: 32px;
  border-radius: 8px;
`
const TokenLogoBubble = styled(DetailBubble)`
  width: 32px;
  height: 32px;
  border-radius: 50%;
`
const TitleBubble = styled(DetailBubble)`
  width: 136px;
`

const WideBubble = styled(DetailBubble)`
  margin-bottom: 6px;
  width: 100%;
`

const ThinTitleBubble = styled(WideBubble)`
  width: 120px;
`

const HalfWideBubble = styled(WideBubble)`
  width: 50%;
`

const ExtraDetailsContainer = styled.div`
  padding-top: 24px;
`

const Space = styled.div<{ heightSize: number }>`
  height: ${({ heightSize }) => `${heightSize}px`};
`

/* Loading State: row component with loading bubbles */
export default function FarmDetailsSkeleton() {
  const { chainName } = useParams<{ chainName?: string }>()
  return (
    <LeftPanel>
      <BreadcrumbNavLink to={chainName ? `/farms/v3` : `/explore`}>
        <ArrowLeft size={14} /> Farms
      </BreadcrumbNavLink>
      <TokenInfoContainer>
        <TokenNameCell>
          <TokenLogoBubble />
          <TitleBubble />
        </TokenNameCell>
      </TokenInfoContainer>
      <Space heightSize={4} />
      <Hr />
      <WideBubble />
      <WideBubble />
      <HalfWideBubble style={{ marginBottom: '24px' }} />
      <ExtraDetailsContainer>
        <ThinTitleBubble />
        <HalfWideBubble />
      </ExtraDetailsContainer>
      <ExtraDetailsContainer>
        <ThinTitleBubble />
        <HalfWideBubble />
      </ExtraDetailsContainer>
    </LeftPanel>
  )
}

function FarmDetailsPageSkeleton() {
  return (
    <TokenDetailsLayout>
      <FarmDetailsSkeleton />
      <RightPanel>
        <AddLiquiditySkeleton />
      </RightPanel>
    </TokenDetailsLayout>
  )
}
