import { BEST_SWAP_NAME } from 'constants/misc'

import { SwapRouterOptions } from './types'

export const bestTradeOptions = [
  { label: 'Auto', value: SwapRouterOptions.AUTO },
  { label: BEST_SWAP_NAME, value: SwapRouterOptions.DEX },
  { label: 'Liquidity Hub', value: SwapRouterOptions.LH },
]

export const bestSwapOptionsAllChains = [{ label: BEST_SWAP_NAME, value: SwapRouterOptions.DEX }]
export const clientSwapOptions = [
  { label: 'Auto', value: SwapRouterOptions.AUTO },
  { label: 'Client', value: SwapRouterOptions.DEX },
  { label: 'Liquidity Hub', value: SwapRouterOptions.LH },
]

export const LH_ROUTER_LABEL = 'Liquidity Hub'
