import { ComponentProps } from 'react'

export const PoolsIcon = (props: ComponentProps<'svg'>) => (
  <svg viewBox="0 0 24 24" fill="none" width="24px" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 2h7.539L15 5.462v5.646l4.018 5.424A5 5 0 0120 19.508V22H4v-2.492a5 5 0 01.982-2.976L9 11.107V2zm2 2v7.768L10.087 13H12v2H8.606l-.741 1H11v2H6.406A3 3 0 006 19.508V20h12v-.492a3 3 0 00-.59-1.786L13 11.767v-6.73L13.461 4H11z"
    ></path>
  </svg>
)
