import { Trans } from '@lingui/macro'
import { Currency, Percent, TradeType } from '@uniswap/sdk-core'
import Column, { AutoColumn } from 'components/Column'
import { IHasTradeProperties } from 'state/routing/types'
import { Field } from 'state/swap/actions'
import styled from 'styled-components'
import { Divider, ThemedText } from 'theme'
import { rateMaximumAmountIn, rateMinimumAmountOut } from 'utils/calculateSlippageAmount'

import { SwapModalHeaderAmount } from './SwapModalHeaderAmount'

const Rule = styled(Divider)`
  margin: 16px 2px 24px 2px;
`

const HeaderContainer = styled(AutoColumn)`
  margin-top: 16px;
`

export default function SwapModalHeader({
  trade,
  inputCurrency,
  allowedSlippage,
}: {
  trade: IHasTradeProperties
  inputCurrency?: Currency
  allowedSlippage: Percent
}) {
  return (
    <HeaderContainer gap="sm">
      <Column gap="lg">
        <SwapModalHeaderAmount
          field={Field.INPUT}
          label={<Trans>You pay</Trans>}
          amount={trade.inputAmount}
          currency={inputCurrency ?? trade.inputAmount.currency}
          usdAmount={trade.fiatValueInput.data}
        />
        <SwapModalHeaderAmount
          field={Field.OUTPUT}
          label={<Trans>You receive</Trans>}
          amount={trade.postTaxOutputAmount}
          currency={trade.outputAmount.currency}
          usdAmount={trade.fiatValueOutput.data}
          tooltipText={
            trade.tradeType === TradeType.EXACT_INPUT ? (
              <ThemedText.BodySmall>
                <Trans>
                  Output is estimated. You will receive at least{' '}
                  <b>
                    {rateMinimumAmountOut(trade, allowedSlippage, trade.outputAmount).toSignificant(6)}{' '}
                    {trade.outputAmount.currency.symbol}
                  </b>{' '}
                  or the transaction will revert.
                </Trans>
              </ThemedText.BodySmall>
            ) : (
              <ThemedText.BodySmall>
                <Trans>
                  Input is estimated. You will sell at most{' '}
                  <b>
                    {rateMaximumAmountIn(trade, allowedSlippage, trade.inputAmount).toSignificant(6)}{' '}
                    {trade.inputAmount.currency.symbol}
                  </b>{' '}
                  or the transaction will revert.
                </Trans>
              </ThemedText.BodySmall>
            )
          }
        />
      </Column>
      <Rule />
    </HeaderContainer>
  )
}
