import { ComponentProps } from 'react'

export const YourPoolsIcon = (props: ComponentProps<'svg'>) => (
  <svg viewBox="0 0 24 24" fill="currentColor" width="20px" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 2h7.618L12 5.236v3.438a7 7 0 11-6 0V2zm4 2.764V10.1A5.002 5.002 0 019 20a4.993 4.993 0 01-4-2h3v-2H4.1a5.022 5.022 0 01-.1-1h5v-2H4.416A5.009 5.009 0 018 10.1V4h2.382L10 4.764zM20 6a1 1 0 11-2 0 1 1 0 012 0zm2 0a3 3 0 11-6 0 3 3 0 016 0zm-1 10a1 1 0 100-2 1 1 0 000 2zm-2-4a1 1 0 11-2 0 1 1 0 012 0z"
    ></path>
  </svg>
)
