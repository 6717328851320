import { useWeb3React } from '@web3-react/core'
import { BigNumber } from 'bignumber.js'
import { farmPricesAtom } from 'components/PriceFetchers/FarmPriceFetcherComponent'
import { FANTOM_BOO_FARM_ADDRESS, FANTOM_LZUSDC_FARM_ADDRESS } from 'constants/misc'
import { useAtom } from 'jotai'
import { useEffect, useMemo, useState } from 'react'

import { useBooLand, useSpook } from './useContract'

export const useXBooRatio = () => {
  const [ratio, setRatio] = useState<BigNumber>()
  const xBooContract = useBooLand()

  useEffect(() => {
    async function fetchxBOORatioSupply() {
      try {
        if (!xBooContract) {
          console.error('xBooContract is not initialized')
          return
        }

        // Call the xBOOForBOO function
        const booBalance = await xBooContract.xBOOForBOO('1000000000000000000')

        setRatio(new BigNumber(booBalance.toString() || '0'))
      } catch (e) {
        console.error('Failed to fetch xBOO ratio:', e.message, e)
      }
    }

    fetchxBOORatioSupply()
  }, [xBooContract])

  return ratio
}

const toBigNumber = (value: string | number | undefined): BigNumber => {
  return new BigNumber(value || '0')
}

// eslint-disable-next-line import/no-unused-modules
export const useBooPrice = () => {
  const { chainId } = useWeb3React()
  const [fetchedFarmData] = useAtom(farmPricesAtom)

  const booFtmFarm = useMemo(
    () => fetchedFarmData.find((farm) => farm.chainId === chainId && farm.lpAddress === FANTOM_BOO_FARM_ADDRESS),
    [fetchedFarmData, chainId]
  )

  const ftmUsdcFarm = useMemo(
    () => fetchedFarmData.find((farm) => farm.chainId === chainId && farm.lpAddress === FANTOM_LZUSDC_FARM_ADDRESS),
    [fetchedFarmData, chainId]
  )

  const prices = useMemo(() => {
    if (!booFtmFarm || !ftmUsdcFarm) {
      return { booUsdPrice: '0' }
    }

    const booFtmTokenReserve = toBigNumber(booFtmFarm.tokenReserve?.toFixed() || '0')
    const booFtmQuoteTokenReserve = toBigNumber(booFtmFarm.quoteTokenReserve?.toFixed() || '0')

    const ftmUsdcTokenReserve = toBigNumber(ftmUsdcFarm.tokenReserve?.toFixed() || '0')
    const ftmUsdcQuoteTokenReserve = toBigNumber(ftmUsdcFarm.quoteTokenReserve?.toFixed() || '0')

    const booFtmPrice = booFtmQuoteTokenReserve.isZero()
      ? toBigNumber(0)
      : booFtmTokenReserve.div(booFtmQuoteTokenReserve)

    const ftmUsdcPrice = ftmUsdcQuoteTokenReserve.isZero()
      ? toBigNumber(0)
      : ftmUsdcTokenReserve.div(ftmUsdcQuoteTokenReserve)

    const booUsdPrice = booFtmPrice.isZero() || ftmUsdcPrice.isZero() ? toBigNumber(0) : booFtmPrice.times(ftmUsdcPrice)

    return {
      booUsdPrice: booUsdPrice.toFixed(),
    }
  }, [booFtmFarm, ftmUsdcFarm])

  return prices
}

const useBooSupply = (address?: string) => {
  const [totalSupply, setTotalSupply] = useState<BigNumber>(new BigNumber(0))
  const booContract = useSpook()

  useEffect(() => {
    async function fetchTotalSupply() {
      try {
        if (!address || !booContract) return

        const booBalance = await booContract.balanceOf(address)

        setTotalSupply(new BigNumber(booBalance.toString() || '0'))
      } catch (e) {
        console.error('Failed to fetch BOO supply:', e)
      }
    }

    fetchTotalSupply()
  }, [address, booContract])

  return totalSupply
}

export default useBooSupply
